import { createContext, useState } from "react";
//importar urlGlobal

import { useEffect } from "react";
import Cookies from "js-cookie"
import { UrlServer } from "../util/conect";
export const UserContext = createContext()

export const UserProvider = ({ children }) => {

  const [user, setUser] = useState()
  const [dataBase, setDataBase] = useState()  
  const [dadosAPI, setDadosAPI] = useState()
  const [tema, setTema] = useState('padrao')

  function data() {
    return {
      token: (JSON.parse(Cookies.get('stk-log'))).token,
      id: (JSON.parse(Cookies.get('stk-log'))).id
    }
  }
 

  useEffect(() => {

   

    fetch(`${UrlServer()}/verificar`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: Cookies.get('stk-log') ?  JSON.stringify(data()) : JSON.stringify({id: null, token: null})
    })
      .then(response => response.json())
      .then((body) => {

       
        setUser(body.response)

      })
      .catch(err => {


      });

   
  }, [])

  setTimeout(() => {   
    window.location.reload()    
  }, 1800000);


  return (
    <UserContext.Provider value={{ user, setUser, dataBase, setDataBase, dadosAPI, setDadosAPI,tema, setTema }}>
      {children}
    </UserContext.Provider>
  )

}


