import './cadastrarPlano.css'
import Select from 'react-select'
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import {
    Button, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, ModalBody,
    Modal, ModalHeader, Progress, FormFeedback
} from 'reactstrap';

//props alerta info cadastro
import SuccessEnvioDados from '../../../util/SuccessEnvioDados'

//importar função fetch
import { ConectURLPost } from '../../../util/conect'



//validação de form
import { useForm } from 'react-hook-form';

//FormInpu paar validar dados
import FormInput from '../../../util/validarInput'


export default function CadastrarPlano() {


    //validação de form 
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const [tipoContrato, settipoContrato] = useState('MENSAL');


    //aguardar tempo
    const [aguardeTempo, setaguardeTempo] = useState(false);

    //carregando dados recebido do fetch
    const [errorResponse, setErrrorResponse] = useState(false)
    const [loadResponse, setloadResponse] = useState(false)
    const [sucessoFetch, setsucessoFetch] = useState(false)
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);

    return (

        <>

            <Modal isOpen={true} fullscreen>
                <ModalHeader id='modalTopo'>
                    <div>Cadastro de Plano</div>
                    <Link className='btn-close' to='/listar-planos'></Link>
                    {aguardeTempo && <Navigate to="/listar-planos" replace={true} />}
                </ModalHeader>
                <ModalBody>

                    <div id='cadastrar-plano'>



                        <div className="box-shadow">
                            <Form>
                                <Row>


                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="nomePlano">
                                                Nome do plano:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.nomePlano ? true : false}
                                                type="text"
                                                name="nomePlano"
                                                register={register}
                                                id="nomePlano"
                                                placeholder="Plano Premium"
                                            />

                                            <FormFeedback >
                                                Campo obrigatório
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>



                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="formPagemnto">
                                                Tipo de pagamento:
                                            </Label>

                                            <Select

                                                name="formPagemnto"
                                                id="formPagemnto"
                                                placeholder='Escolha'
                                                options={[
                                                    { value: 'DIARIO', label: 'Diário' },
                                                    { value: 'MENSAL', label: 'Mensal' },
                                                    { value: 'TRIMESTRAL', label: 'Trimestral' },
                                                    { value: 'ANUAL', label: 'Anual' },

                                                ]}

                                                onChange={(e) => {
                                                    settipoContrato(e.value)
                                                }}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>

                                            <Label for="valorPlano">
                                                Valor:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.valorPlano ? true : false}
                                                type="number"
                                                name="valorPlano"
                                                id="valorPlano"
                                                register={register}
                                                placeholder="250"
                                            />

                                            <FormFeedback >
                                                Campo obrigatório
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>


                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="quantTerminal">
                                                    Quantidade de terminais:
                                                </Label>

                                                <FormInput
                                                    invalid={errors?.quantTerminal ? true : false}
                                                    type="number"
                                                    name="quantTerminal"
                                                    register={register}
                                                    id="quantTerminal"
                                                    placeholder=""
                                                />

                                                <FormFeedback >
                                                    Campo obrigatório
                                                </FormFeedback>

                                            </FormGroup>
                                        </Col>

                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="labelPlano">
                                                    Rótulo caixa de preço (opcional):
                                                </Label>

                                                <FormInput
                                                    invalid={false}
                                                    type="text"
                                                    name="labelPlano"
                                                    register={register}
                                                    id="labelPlano"
                                                    placeholder="Ex: economize R$ 90,00"
                                                />

                                                <FormFeedback >
                                                    Campo obrigatório
                                                </FormFeedback>

                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>

                                                <Label for="infoPlano">
                                                    Descrição do plano:
                                                </Label>

                                                <FormInput
                                                    invalid={errors?.infoPlano ? true : false}
                                                    type="textarea"
                                                    name="infoPlano"
                                                    id="infoPlano"
                                                    register={register}
                                                    placeholder="02 pontos comerciais e 01 vídeio de 15s grátis."
                                                />

                                                <FormFeedback >
                                                    Campo obrigatório
                                                </FormFeedback>

                                            </FormGroup>
                                        </Col>
                                    </Row>



                                </Row>

                                <FormGroup check>

                                    <FormInput
                                        invalid={errors?.planoAtivo ? true : false}
                                        type="checkbox"
                                        name="planoAtivo"
                                        id="planoAtivo"
                                        register={register}

                                    />

                                    <FormFeedback >
                                        Campo obrigatório
                                    </FormFeedback>

                                    <Label
                                        check
                                        for="planoAtivo"
                                    >
                                        Plano ativo
                                    </Label>
                                </FormGroup>

                            </Form>
                        </div>





                        <div className="box-footer">
                            <Card style={{ border: 'none' }}>
                                <CardFooter>

                                    {
                                        !sucessoFetch
                                            ?
                                            (<Button color="success" onClick={() => {


                                                //rota,getContrato,setloadResponse,setsucessoFetch,setaguardeTempo       

                                                handleSubmit((data) => {

                                                    const enviar = { ...data, formPagemnto: tipoContrato }

                                                    ConectURLPost(
                                                        'cadastro-plano',
                                                        enviar,
                                                        setloadResponse,
                                                        setsucessoFetch,
                                                        setaguardeTempo,
                                                        setErrrorResponse
                                                    )
                                                })()

                                            }}>
                                                Salvar
                                            </Button>)
                                            :
                                            null
                                    }




                                </CardFooter>
                            </Card>
                        </div>
                        {loadResponse && <Progress
                            animated
                            value={100}
                        />}
                    </div>


                </ModalBody>

                <SuccessEnvioDados
                    sucessoFetch={sucessoFetch} //
                    menssagem={'Plano cadastrado'}
                    visible={visible}
                    onDismiss={onDismiss}
                />

                <SuccessEnvioDados
                    sucessoFetch={errorResponse} //
                    menssagem={'Não foi possivel se conectar com oservidor. Tente novamente mais tarde.'}
                    visible={visible}
                    onDismiss={onDismiss}
                    danger={'danger'}
                />

            </Modal>

        </>


    )
}


