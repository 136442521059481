
import { Navigate } from 'react-router';
import { UserContext } from '../../context/UserContext'
import { useContext } from 'react';


import Cookies from 'js-cookie';





export default function Private({ children }) {

  const { user,  } = useContext(UserContext)  

  const  token = Cookies.get('stk-log') ? JSON.parse(Cookies.get('stk-log')).token : false

  return user || token ? children : <Navigate to="/login" />


}