import './terminalCadastrado.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import TuneIcon from '@mui/icons-material/Tune';

import {  useLocation } from 'react-router';
import {
    Modal, ModalHeader, ModalBody,
} from 'reactstrap';

import VerTerminal from './verTErminal'






export default function Cliente() {

    const { state: { terminal, vendas } } = useLocation();

    
    const [retornar, setRetornat] = useState(false)


    //Modal config
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    return (

        <div className="stk-body">

            {retornar && <Navigate to="/listar-terminais" replace={true} />}

            <div id='terminal-cadastrado' >

                <div className="topo-terminal">
                    <KeyboardReturnIcon
                        onClick={() => {
                            setRetornat(true)
                        }}>
                    </KeyboardReturnIcon>

                    <TuneIcon onClick={() => {
                        setModal(true)
                    }}>
                    </TuneIcon>

                </div>
 

                <div className="titulo-um" style={{ marginTop: '10px' }}>{terminal.nomeEmpresa}</div>
                <div className="box-shadow">


                    <div className="box-terminal-cadastrado">
                        <div style={{ padding: '6px' }}>Terminal ativo: {`${terminal.empresaAtiva ? 'sim' : 'não'}`}</div>
                        <div style={{ padding: '6px' }}>Responsável: {`${terminal.responsavelEmpresa}`}</div>
                        <div style={{ padding: '6px' }}>
                            WhatsApp: {`${terminal.whatsApp}`}  <WhatsAppIcon /></div>

                    </div>
                </div>

            </div>

            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>{terminal?.nomeEmpresa || ''}</ModalHeader>
                <ModalBody>
                    <VerTerminal dados={terminal} vendas={vendas}/>
                </ModalBody>
            </Modal>

        </div>

    )
}


