import React, { useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom'
import {
    Button, Input, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, Progress, Alert,

} from 'reactstrap';

//importar função fetch
import { ConectURL } from '../../../util/conect'

//Retirar caracteres especial 
import { RemoverCaracteres } from '../../../util/tools'


export default function Cliente({ dados, vendas }) {

    //confirmação alerta info
    const [visibleCompAlert, setVisibleCompAlert] = useState(false);
    const [msgAlert, setMsgAlert] = useState();




    //todos os dados do Form
    const [getContrato, setContrato] = useState(
        { ...dados }
    );

    //console.log(dados)

    //carregando dados recebido do fetch
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })


    //alerta fim do update alertToggle
    const [alertTg, setAlertTg] = useState(true);

    const navegacao = useNavigate()

    function alertToggle() {
        setAlertTg((to) => !to)
        setfetchErro((body) => {
            return {
                ...body,
                btn: false
            }
        })
    }

    function verificarFetch() {

        if (fetchErro.res) {

            if (fetchErro.tipo === 'carregando') {
                return (

                    <Progress
                        animated
                        value={100}
                        color="warning"
                    />

                )
            } else if (fetchErro.tipo === 'error') {

                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert color="danger">
                            <code style={{ fontSize: '.8rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>

                )
            } else if (fetchErro.tipo === 'sucesso') {



                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert
                            isOpen={alertTg}
                            toggle={alertToggle}
                            color={fetchErro.metodoFetch === 'DELETE' ? 'danger' : 'success'}
                            onClick={() => {

                                navegacao('/listar-terminais')
                                window.location.reload()

                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                {fetchErro.msg}
                            </span>

                        </Alert>
                    </div>

                )
            }
        }
    }


    //vrificar confirmação
    function CompConfimacao(visibleCompAlert, setVisibleCompAlert) {
        return (
            <>
                <div id="comp-alert-confirm">
                    <Alert color="danger" isOpen={visibleCompAlert} style={{ maxWidth: '300px' }}>

                        {msgAlert.msg}

                        <div id='btn-alert-confirm'>

                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => {
                                    setVisibleCompAlert(false)


                                }}
                            >
                                Não
                            </Button>
                            <Button
                                color="success"
                                size="sm"
                                onClick={() => {

                                    setVisibleCompAlert(false)


                                    msgAlert.tipo === 'deletar' ?

                                        //metodo,rota, msg, body, setfetchErro, id
                                        ConectURL(
                                            'DELETE',
                                            'editar-terminal',
                                            'Terminal deletado',
                                            null,
                                            setfetchErro,
                                            getContrato._id
                                        )
                                        :
                                          
                                        ConectURL(
                                            'PATCH',
                                            'editar-terminal',
                                            'Terminal editado',
                                            getContrato,
                                            setfetchErro,
                                            getContrato._id
                                        )

                                }}
                            >
                                Sim
                            </Button>


                        </div>

                    </Alert>
                </div>
            </>
        )
    }

    //verificar se o terminal tem anuncio
    function verificarTerminal() {

        let idCliente = []
        vendas.forEach(element => {

            element.terminalOption.forEach((val) => {
                idCliente.push(val.split('-')[1])
            })


        });

        return !idCliente.includes(dados._id)
    }

    return (


        <div id='anunciante-body' >


            <div className="box-shadow">

                <Form id='dadosDaEmpresa'>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="nomeEmpresa">
                                    Nome:
                                </Label>
                                <Input
                                    id="nomeEmpresa"
                                    name="nomeEmpresa"
                                    placeholder="Nome da empresa"
                                    type="text"
                                    defaultValue={getContrato.nomeEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                nomeEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="responsavelEmpresa">
                                    Responsável:
                                </Label>

                                <Input
                                    id="responsavelEmpresa"
                                    name="responsavelEmpresa"
                                    placeholder="Nome do Responsável"
                                    type="text"
                                    defaultValue={getContrato.responsavelEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                responsavelEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="documentoEmpresa">
                                    CPF / CNPJ:
                                </Label>
                                <Input
                                    id="documentoEmpresa"
                                    name="documentoEmpresa"
                                    type="text"
                                    defaultValue={getContrato.documentoEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                documentoEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="whatsApp">
                                    WhatsApp:
                                </Label>
                                <Input
                                    id="whatsApp"
                                    name="whatsApp"
                                    type="text"
                                    defaultValue={getContrato.whatsApp}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                whatsApp: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col >
                            <FormGroup>
                                <Label for="enderecoEmpresa">
                                    Endereço:
                                </Label>
                                <Input
                                    id="enderecoEmpresa"
                                    name="enderecoEmpresa"
                                    type="text"
                                    defaultValue={getContrato.enderecoEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                enderecoEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="cidadeEmpresa">
                                    Cidade:
                                </Label>
                                <Input
                                    id="cidadeEmpresa"
                                    name="cidadeEmpresa"
                                    defaultValue={getContrato.cidadeEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                cidadeEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="estadoEmpresa">
                                    Estado:
                                </Label>
                                <Input
                                    id="estadoEmpresa"
                                    name="estadoEmpresa"
                                    defaultValue={getContrato.estadoEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                estadoEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="cepEmpresa">
                                    CEP:
                                </Label>
                                <Input
                                    id="cepEmpresa"
                                    name="cepEmpresa"
                                    defaultValue={getContrato.cepEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                cepEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup check>

                        <Input
                            id="empresaAtiva"
                            name="empresaAtiva"
                            type="checkbox"
                            defaultChecked={getContrato.empresaAtiva}
                            onChange={(e) => {
                                setContrato((body) => {
                                    return {
                                        ...body,
                                        empresaAtiva: e.target.checked
                                    }
                                })
                            }}
                        />
                        <Label
                            check
                            for="empresaAtiva"

                        >
                            Empresa ativa
                        </Label>
                    </FormGroup>

                </Form>
            </div>

            <div className="box-footer">
                <Card style={{ border: 'none' }}>
                    <CardFooter>

                        {verificarTerminal() && < Button color="danger" onClick={() => {

                            setVisibleCompAlert(true)
                            setMsgAlert(() => {
                                return {
                                    tipo: 'deletar',
                                    msg: 'Você tem certeza de que deseja deletar esse cliente?'
                                }
                            })


                        }}>
                        Deletar
                    </Button>}


                    <Button color="primary" onClick={() => {

                        setVisibleCompAlert(true)
                        setMsgAlert(() => {
                            return {
                                tipo: 'editar',
                                msg: 'Você tem certeza de que deseja editar esse cliente?'
                            }
                        })

                    }}>
                        Editar
                    </Button>

                </CardFooter>
            </Card>
        </div>
            { fetchErro.btn && verificarFetch() }

    { visibleCompAlert && CompConfimacao(visibleCompAlert, setVisibleCompAlert) }

        </div >
    )
}


