
import './nossosNumeros.css'

import IndexContador from './contador/indexContador'


export default function NossosNumeros({ dadosEmpresa }) {



    return (
        <>

            <div id="containerNossosNumeros">
                <div>
                    <div id="TituloPlano" className="nossosNumeros">
                        Nossos números
                    </div>
                </div>
                <div className='boxPai'>
                    <div className="boxNumeros">

                        <div className="quantNumeros">

                            <div className="numeroQuant" style={{ color: dadosEmpresa?.corSelecionada }}>
                                <IndexContador alvo={dadosEmpresa?.quantTelas ? dadosEmpresa?.quantTelas : 0} />
                            </div>

                            <div className="textoQuant">Telas</div>
                            <div className="tracoQuant" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}></div>
                        </div>



                        <div className="quantNumeros">
                            <div className="numeroQuant" style={{ color: dadosEmpresa?.corSelecionada }}>
                                <IndexContador alvo={dadosEmpresa?.quantClientes ? dadosEmpresa?.quantClientes : 0} />
                            </div>

                            <div className="textoQuant">Clientes</div>
                            <div className="tracoQuant" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}></div>
                        </div>

                        <div className="quantNumeros">
                            <div className="numeroQuant" style={{ color: dadosEmpresa?.corSelecionada }}>
                            
                            <IndexContador alvo={dadosEmpresa?.quantCidade ? dadosEmpresa?.quantCidade : 0} />
                            </div>
                            <div className="textoQuant">Cidades</div>
                            <div className="tracoQuant" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}></div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}