import './topo.css'



import { Link } from 'react-router-dom'

import SvgMenu from './svgMenu';



import LogoStk from '../../img/logo.png'
import { UserContext } from '../../context/UserContext';
import { useContext } from 'react';


export default function Topo({ setOpenMenu }) {

  const { tema, setTema } = useContext(UserContext)

 

  return (

    <>
      <div id='topo' className={tema === 'padrao' ? `${tema}-topo` : `${tema}-topo`}>
        <SvgMenu setOpenMenu={setOpenMenu} />

        <Link to={"/"}>

        </Link>
        <Link to={"/"}>
          <img src={LogoStk} alt='Logo Stoke Midia'></img>
        </Link>


      </div>

    </>

  )
}

