import './topoProposta.css'

import { useEffect, useState, useRef } from 'react';

import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';




export default function TopoProposta({ dadosEmpresa, videobg }) {

    const [atTop, setAtTop] = useState(true); // Assume que começa no topo

    useEffect(() => {
        const handleScroll = () => {
            // Define como verdadeiro se o scroll estiver no topo
            setAtTop(window.scrollY === 0);
        };

        // Adiciona o event listener ao scroll
        window.addEventListener('scroll', handleScroll);

        // Limpeza: remove o event listener quando o componente é desmontado
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div className={`topoProposta ${atTop ? 'show' : 'hide'}`}
                style={
                    {
                        backgroundColor: videobg ? "rgba(0,0,0,.0)" : dadosEmpresa?.corSelecionada,
                        color: '#fff'
                    }
                }>
                <div className="nomeEmpresaProposta" style={{ color: '#fff' || dadosEmpresa?.corSelecionada }}>
                    {`${dadosEmpresa && dadosEmpresa?.nomeEmpresaProposta}`}
                </div>
                
                <div className="contatoPropostaTopo">

                    <div className="zapProposta">
                        <a target="_blank" href={dadosEmpresa && "https://wa.me/55" + dadosEmpresa?.whatsApp}>
                            <WhatsAppIcon style={{ color: '#fff' || dadosEmpresa?.corSelecionada }} />
                        </a>
                    </div>
                    <div className="instamProposta">
                        <a target="_blank" href={dadosEmpresa && "https://www.instagram.com/" + dadosEmpresa?.instagram}>
                            <InstagramIcon style={{ color: '#fff' || dadosEmpresa?.corSelecionada }} />
                        </a>
                    </div>

                </div>
            </div>
        </>
    )
}