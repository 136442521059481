import './settings.css'

import { useEffect, useState } from 'react';
import { ConectURL, UrlServer } from '../../util/conect';
import Cookies from 'js-cookie';
import {
    Input, Row, Col, Form, FormGroup, Label, Card, Button, Alert, Progress, Modal, ModalBody, ModalFooter, ModalHeader, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AssessmentIcon from '@mui/icons-material/Assessment';

import BoxConfig from './boxConfig';
import { HeadersGet } from '../../util/headers';
import CorPrincipal from './CorPrincipal';
import ModeloSite from './modeloSite/modeloSite';
import BackgroundVideo from './backgroundVideo/backgroundVideo';
import UploadImg from './uploadImg/uploadImg';

import IconesCard from '../proposta/IconesCard/IconesCard';







export default function Settings() {

    const [newSenha, setSenha] = useState({})
    const [newTextZap, setTextZap] = useState({})
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })
    const [alertTg, setAlertTg] = useState(true);
    const [modal, setModal] = useState(false);
    const [tipo, setTipo] = useState(false);
    const [Proposta, setProposta] = useState({})
    const [relatorio, setRelatorio] = useState({})



    //accordion
    const [openAccordion, setOpenAccordion] = useState('0');

    const toggleAccordion = (id) => {
        if (openAccordion === id) {
            setOpenAccordion();
        } else {
            setOpenAccordion(id);
        }
    };


    const toggle = () => setModal(!modal);

    useEffect(() => {

        fetch(`${UrlServer()}/buscar-texto/`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.response) {

                    setTextZap(
                        {
                            textoZapBody: data.response[0].textoZapBody,
                            textoZapFooter: data.response[0].textoZapFooter,
                            id: data.response[0]._id,


                        }
                    )
                }
            }
            )

        //buscar dadsos server conf proposta
        fetch(`${UrlServer()}/buscar-proposta/`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.response) {


                    const res = {
                        cnpjEmpresaProposta: data.response[0].cnpjEmpresaProposta,
                        corSelecionada: data.response[0].corSelecionada,
                        emailProposta: data.response[0].emailProposta,
                        instagram: data.response[0].instagram,
                        nomeEmpresaProposta: data.response[0].nomeEmpresaProposta,
                        tituloPrimario: data.response[0].tituloPrimario,
                        tituloSecundario: data.response[0].tituloSecundario,
                        whatsApp: data.response[0].whatsApp,
                        _id: data.response[0]._id,
                        quantTelas: data.response[0].quantTelas,
                        quantClientes: data.response[0].quantClientes,
                        quantCidade: data.response[0].quantCidade,
                        quantInsercoes: data.response[0].quantInsercoes,

                        videoBg: data.response[0]?.videoBg || 1,
                        habilitarBtn: data.response[0]?.habilitarBtn || false,
                        corBtn: data.response[0].corBtn || data.response[0].corSelecionada,

                        marcasClientes: data.response[0].marcasClientes || data.response[0].marcasClientes,
                        marcasClientesDelete: data.response[0].marcasClientesDelete || data.response[0].marcasClientesDelete,
                        insercoesMes: data.response[0].insercoesMes || data.response[0].insercoesMes,
                        insercoesMesDelete: data.response[0].insercoesMesDelete || data.response[0].insercoesMesDelete,

                        iconeCor: data.response[0].iconeCor || "#515151",
                        iconeCard: data.response[0].iconeCard || "Icon_01",
                        iconeTamanho: data.response[0].iconeTamanho || "4",

                        ajusteImgExtra: data.response[0].ajusteImgExtra || "cover",

                    }

                    setProposta(res)

                }
            }
            )

        //buscar relatio
        fetch(`${UrlServer()}/relatorios`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.response) {

                    setRelatorio(data.response)
                }
            }
            ).catch((e) => console.log(e))

    }, [])


    function SalvarDados(parm) {


        switch (parm) {
            case 'Conta':
                ConectURL(
                    'PATCH',
                    'editar-sn',
                    'Senha Atualizada',
                    newSenha,
                    setfetchErro,
                    (JSON.parse(Cookies.get('stk-log'))).id,

                )
                break;
            case 'Whatsapp':

                ConectURL(
                    'PATCH',
                    'editar-texto',
                    'Texto Atualizada',
                    newTextZap,
                    setfetchErro,
                    newTextZap.id,

                )
                break;
            case 'Proposta comercial':



                ConectURL(
                    'PATCH',
                    'editar-proposta',
                    'Dados Atualizada',
                    Proposta,
                    setfetchErro,
                    Proposta._id,

                )


                break
            default:
                break;
        }



    }

    function alertToggle() {
        setAlertTg((to) => false)
        setfetchErro((body) => {
            return {
                ...body,
                btn: false
            }
        })
    }
    function verificarFetch() {

        if (fetchErro.res) {

            if (fetchErro.tipo === 'carregando') {
                return (

                    <Progress
                        animated
                        value={100}
                        color="warning"
                    />

                )
            } else if (fetchErro.tipo === 'sucesso') {

                return (

                    <div id={alertTg ? 'alert-fetch-sucess' : null} className='sucessoFetch'>
                        <Alert
                            isOpen={alertTg}
                            toggle={alertToggle}
                            color={fetchErro.metodoFetch === 'DELETE' ? 'danger' : 'success'}
                            onClick={() => {

                                if (fetchErro.msg !== "Texto Atualizada") {
                                    //Cookies.remove('stk-log')
                                    //window.location.reload();
                                } else {
                                    //window.location.reload();
                                }

                                //{ btn: false, res: true, tipo: 'carregando', msg: '' }
                                setfetchErro(body => {
                                    return {
                                        ...body,
                                        tipo: 'carregando'
                                    }
                                })
                                setAlertTg(al => !al)

                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                {fetchErro.msg}
                            </span>

                        </Alert>

                    </div>

                )
            }
        }
    }

    function printEditarUser() {
        return (
            <>
                <Form>

                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label for="textoZap">
                                    Nova senha:
                                </Label>
                                <Input autoComplete='' id='novaSenha' type='password' onChange={(e) => setSenha((val) => {
                                    return {
                                        ...val,
                                        senha: e.target.value
                                    }
                                })}></Input>
                            </FormGroup>
                        </Col>

                        <Col md="6">
                            <FormGroup>
                                <Label for="confirmeSenha">
                                    Confirmar senha:
                                </Label>
                                <Input autoComplete='' id='confirmeSenha' type='password' onChange={(e) => setSenha((val) => {
                                    return {
                                        ...val,
                                        confirmSenha: e.target.value
                                    }
                                })}></Input>
                            </FormGroup>
                        </Col>

                    </Row>
                </Form>
            </>
        )
    }

    function printwhatsapp() {
        return (
            <>
                <Form>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='textoZapBody'>Cabeçalho:</Label>
                                <Input rows="5" autoComplete='' id='textoZapBody' type='textarea' defaultValue={newTextZap.textoZapBody} onChange={(e) => setTextZap((val) => {
                                    return {
                                        ...val,
                                        textoZapBody: e.target.value
                                    }
                                })}></Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='textoZapFooter'>Rodapé:</Label>
                                <Input rows="5" autoComplete='' id='textoZapFooter' type='textarea' defaultValue={newTextZap.textoZapFooter} onChange={(e) => setTextZap((val) => {
                                    return {
                                        ...val,
                                        textoZapFooter: e.target.value
                                    }
                                })}></Input>
                            </FormGroup>
                        </Col>
                    </Row>

                </Form>
            </>
        )
    }

    function PropostaComercial() {


        return (
            <div id='formPropostaComercial'>

                <div className="boxPropostaUm">
                    <ModeloSite dadosEmpresa={Proposta} setProposta={setProposta} />
                </div>

                <div className="boxPropostaDois">
                    <Accordion open={openAccordion} toggle={toggleAccordion}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">Dados da empresa</AccordionHeader>
                            <AccordionBody accordionId="1">


                                <Row>
                                    <Col md={12}>
                                        <Label htmlFor='nomeEmpresaProposta' >Nome da Empresa:</Label>
                                        <Input id='nomeEmpresaProposta' defaultValue={Proposta?.nomeEmpresaProposta} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        nomeEmpresaProposta: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                    <Col md={12}>
                                        <Label htmlFor='cnpjEmpresaProposta'>CNPJ da Empresa:</Label>

                                        <Input id='cnpjEmpresaProposta' defaultValue={Proposta?.cnpjEmpresaProposta} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        cnpjEmpresaProposta: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Label htmlFor='zapPorposta'>WhatsApp:</Label>
                                        <Input id='zapPorposta' defaultValue={Proposta?.whatsApp} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        whatsApp: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                    <Col md={12}>
                                        <Label htmlFor='instamProposta' >Instagram:</Label>
                                        <Input id='instamProposta' defaultValue={Proposta?.instagram} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        instagram: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                    <Col>
                                        <Label htmlFor='emailPorposta'>Email:</Label>
                                        <Input id='emailPorposta' defaultValue={Proposta?.emailProposta} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        emailProposta: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                </Row>


                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader targetId="2">Título</AccordionHeader>
                            <AccordionBody accordionId="2">

                                <Row>

                                    <Col>
                                        <FormGroup>
                                            <Label for='tituloPrimario'>1º linha</Label>
                                            <Input rows="4" autoComplete='' id='tituloPrimario' type='textarea' defaultValue={Proposta?.tituloPrimario}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                tituloPrimario: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for='tituloSecundario'>2º linha:</Label>
                                            <Input rows="4" autoComplete='' id='tituloSecundario' type='textarea' defaultValue={Proposta?.tituloSecundario}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                tituloSecundario: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader targetId="3">Estatísticas</AccordionHeader>
                            <AccordionBody accordionId="3">

                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for='quantTelas'>Quantidade de telas:</Label>
                                            <Input id='quantTelas' type='number' defaultValue={Proposta?.quantTelas}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quantTelas: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for='quantClientes'>Quantidade de clientes:</Label>
                                            <Input id='quantClientes' type='number' defaultValue={Proposta?.quantClientes}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quantClientes: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for='quantCidade'>Quantidade de cidades:</Label>
                                            <Input id='quantCidade' type='number' defaultValue={Proposta?.quantCidade}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quantCidade: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>


                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for='quantInsercoes'>Quantidade de inserções mês:</Label>
                                            <Input id='quantInsercoes' type='number' defaultValue={Proposta?.quantInsercoes}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quantInsercoes: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader targetId="4">Personalização</AccordionHeader>
                            <AccordionBody accordionId="4">
                                <Row>
                                    <Col>

                                        {/* Cor Prncipla */}
                                        <>

                                            <div className="boxCorTitulo">
                                                <div className='boxPersonalizarProposta'>
                                                    {Proposta && <CorPrincipal atual={Proposta?.corSelecionada} setProposta={setProposta} tipo={'corSelecionada'} />}
                                                </div>
                                                <div className='tituloPageProposta centralizarTexto'>
                                                    Cor principal
                                                </div>
                                            </div>

                                        </>

                                        {/* Botões */}
                                        <>

                                            <div className="boxCorTitulo">

                                                <div className='boxPersonalizarProposta'>
                                                    {Proposta && <CorPrincipal atual={Proposta?.corSelecionada} setProposta={setProposta} tipo={'corBtn'} />}
                                                </div>

                                                <div className='tituloPageProposta centralizarTexto'>
                                                    Cor dos botões
                                                </div>

                                            </div>

                                        </>
                                        {/* Icones */}
                                        <>

                                            <div className="boxCorTitulo">
                                                <div className="boxPersonalizarProposta">
                                                    <CorPrincipal atual={Proposta?.iconeCor} setProposta={setProposta} tipo={'iconeCor'} />
                                                </div>
                                                <div className='tituloPageProposta centralizarTexto'>
                                                    Cor do icone
                                                </div>
                                            </div>

                                            <div className="rangeIcone">

                                                <div className='tituloPageProposta centralizarTexto '>
                                                    Tamanho do icone
                                                </div>

                                                <Input
                                                    id="iconeTamanho"
                                                    name="range"
                                                    type="range"
                                                    max={10}
                                                    onChange={(e) => {

                                                        setProposta((body) => {
                                                            return {
                                                                ...body,
                                                                iconeTamanho: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>


                                            <div className="centralizarTexto">
                                                <IconesCard Proposta={Proposta} setProposta={setProposta} />
                                            </div>
                                        </>




                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>



                        <AccordionItem>
                            <AccordionHeader targetId="6">Background vídeo</AccordionHeader>
                            <AccordionBody accordionId="6">
                                <Row>
                                    <Col>

                                        <div id='labelVid' className='tituloPageProposta centralizarTexto'
                                        >
                                            <Input
                                                defaultChecked={Proposta?.habilitarBtn}
                                                id="videoBG"
                                                type='checkbox'
                                                onClick={() => {

                                                    setProposta((data) => {
                                                        return {
                                                            ...data,
                                                            habilitarBtn: !Proposta?.habilitarBtn,
                                                            vidEscolhido: require(`../../videos/background/bgVid_${Proposta?.videoBg}.mp4`)
                                                        }
                                                    })

                                                }} />
                                            <Label htmlFor='videoBG'>Habilitar vídeo background</Label>

                                        </div>


                                        {Proposta?.habilitarBtn && <BackgroundVideo Proposta={Proposta} setProposta={setProposta} />}

                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader targetId="7">Clientes</AccordionHeader>
                            <AccordionBody accordionId="7">
                                <Row>
                                    <Col>

                                        <div className='tituloPageProposta centralizarTexto'>
                                            {`Marcas que confiam na ${Proposta?.nomeEmpresaProposta}`}
                                        </div>
                                        <br></br>

                                        {Proposta &&
                                            <UploadImg
                                                idUpload={"marcasClientes"}
                                                nameUpload={"marcasClientes"}
                                                formTextUpload={"Tamanho recomendado para Upload  1200px x 400px"}
                                                chaveAPI={"0bbe7dd51ce9efe913437476f88671cf"}
                                                setProposta={setProposta}
                                                Proposta={Proposta}
                                                rotaUpload={"marcasClientes"}

                                            />}





                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionHeader targetId="8">Upload imagem extra</AccordionHeader>
                            <AccordionBody accordionId="8">
                                <Row>
                                    <Col>

                                        <div className='tituloPageProposta centralizarTexto'>
                                            Imagem inserções por mês
                                        </div>
                                        <br></br>
                                        {Proposta &&
                                            <UploadImg
                                                idUpload={"insercoesMes"}
                                                nameUpload={"insercoesMes"}
                                                formTextUpload={"Tamanho recomendado para Upload  1280 x 900"}
                                                chaveAPI={"0bbe7dd51ce9efe913437476f88671cf"}
                                                setProposta={setProposta}
                                                Proposta={Proposta}
                                                rotaUpload={"insercoesMes"}
                                            />}

                                        <div className="ajusteImagem">

                                            <div className='tituloPageProposta centralizarTexto'>
                                                Ajuste da imagem
                                            </div>


                                            <FormGroup tag="fieldset">

                                                <FormGroup check>
                                                    <Input
                                                        name="ajusteImgExtra"
                                                        id='contain'
                                                        type="radio"
                                                        onChange={(e) => {
                                                            setProposta((body) => {
                                                                return {
                                                                    ...body,
                                                                    ajusteImgExtra: e.target.id
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    {' '}
                                                    <Label check>
                                                        Centralizar contain ideal para png
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        name="ajusteImgExtra"
                                                        type="radio"
                                                        id='cover'
                                                        onChange={(e) => {
                                                            setProposta((body) => {
                                                                return {
                                                                    ...body,
                                                                    ajusteImgExtra: e.target.id
                                                                }
                                                            })

                                                        }}
                                                    />
                                                    {' '}
                                                    <Label check>
                                                        Ajustar tamanho total cover
                                                    </Label>
                                                </FormGroup>
                                            </FormGroup>
                                        </div>



                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>



                    </Accordion>
                </div>


            </div>
        )
    }

    function Relatorios() {

        return (
            <>
                Relatórios
            </>
        )
    }

    function printBody(parm) {

        switch (parm) {
            case 'Conta':
                return { body: printEditarUser(), titulo: 'Alterar senha' }
                break;
            case 'Whatsapp':
                return { body: printwhatsapp(), titulo: 'Texto de cobrança' }
                break;
            case 'Proposta comercial':
                return { body: PropostaComercial(), titulo: 'Proposta comercial' }
                break;
            case 'Relatórios':
                return { body: Relatorios(), titulo: 'Relatórios' }
                break;
            default:
                break;
        }
    }





    function btnSalvar(parm) {

        if (parm === 'Relatórios') {
            return
        }

        function gerarLink() {

            function gerarDados() {

                fetch(`${UrlServer()}/buscar-proposta`, HeadersGet())

            }

            if (tipo === 'Proposta comercial') {
                return (
                    <div className='LinkProposta'>
                        <a onClick={() => gerarDados()} target='_blank'
                            href={`http://${window.location.host}/proposta-comercial?empresa=${JSON.parse(Cookies.get('stk-log')).dbNome.split('_')[1]}`}>
                            Acessar proposta
                        </a>
                    </div>
                )
            } else {
                return (
                    <div></div>
                )
            }
        }

        return (
            <>
                <div id="rodape-btn-salvar">
                    <div className="box-footer ">

                        {
                            fetchErro.msg === 'As senhas não conferem!' ? (<Alert color="danger" isOpen={true}  >
                                <code style={{ fontSize: '.8rem' }}>
                                    {fetchErro.msg}
                                </code>
                            </Alert>) : ''
                        }

                        {gerarLink()}





                        <Button color="success"
                            onClick={(e) => {
                                SalvarDados(parm)
                            }}
                        >
                            Salvar
                        </Button>






                    </div>
                </div>
            </>
        )

    }

    if (Proposta?.tituloPrimario) {
        return (
            <>
                <div id='page-conf' className='stk-body'>
                    <div className="titulo-um">
                        Configurações
                    </div>

                    <div className="box-conf">

                        <BoxConfig icone={<AccountCircleIcon />} titulo={'Conta'} texto={'Alterar senha e nome de usuario'} toggle={toggle} setTipo={setTipo} />

                        <BoxConfig icone={<WhatsAppIcon />} titulo={'Whatsapp'} texto={'Alterar mensagem de cobrança'} toggle={toggle} setTipo={setTipo} />

                        <BoxConfig icone={<AddBusinessIcon />} titulo={'Proposta comercial'} texto={'Alterar texto da tabela de preço'} toggle={toggle} setTipo={setTipo} />

                        {/* <BoxConfig icone={<AssessmentIcon />} titulo={'Relatórios'} texto={'Visualize relatório da sua empresa'} toggle={toggle} setTipo={setTipo} /> */}



                    </div>
                </div>


                <Modal isOpen={modal} toggle={toggle} centered fullscreen={tipo == 'Conta' ? false : true} >
                    <ModalHeader toggle={toggle}>{printBody(tipo)?.titulo}</ModalHeader>
                    <ModalBody>
                        {Proposta?.tituloPrimario && printBody(tipo)?.body}
                    </ModalBody>
                    <ModalFooter>

                        {btnSalvar(tipo)}
                        {fetchErro.btn && verificarFetch()}

                    </ModalFooter>

                </Modal>
            </>
        )
    } else {
        return (
            <div className='stk-body'>
                <div className="carregando-fetch" style={{ paddingBottom: '10px' }}>
                    <Progress
                        animated
                        value={100}
                        color="warning"
                    />
                </div>
            </div>
        )
    }

}