

import './cardHome.css'
import { Button, CardHeader, CardBody, CardText, Card, CardTitle, CardFooter } from 'reactstrap';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useEffect, useState } from 'react';
import { UrlServer } from '../../util/conect';
import { HeadersGet } from '../../util/headers';

export default function CardHome({ body }) {

    const [newTextZap, setTextZap] = useState({})
    
    useEffect(() => {

        fetch(`${UrlServer()}/buscar-texto/`, HeadersGet())
            .then(response => response.json())
            .then(data => {

               
                if (data.response) {
                    setTextZap(
                        {
                            textoZapBody: data.response[0]?.textoZapBody,
                            textoZapFooter: data.response[0]?.textoZapFooter,
                           
                        }
                    )
                }
            }
            )


    }, [])

    function enviarCobranca(dadosCliente) {

        const texto = `Olá, ${dadosCliente.nomeEmpresa}.  \n
${newTextZap.textoZapBody}
\n DADOS DA FATURA 
------------------------------------
Vencimento: ${dadosCliente.fatura}.
------------------------------------
Valor da Fatura: *${dadosCliente.valor}*
------------------------------------
${newTextZap.textoZapFooter}
`
        const link = `https://wa.me/55${dadosCliente.whatsApp}?text=${texto}`

        window.open(encodeURI(link, "_blank", 'noopener', 'noreferrer'))


    }

    function gerarInconepagamento(quant) {


        if (quant > 10) {
            return 'success'
        } else if (quant <= 10 && quant > 5) {
            return 'warning'
        } else {
            return 'error'
        }

    }

    return (
        <div id='card-faturas-home' >


            {body().map((value, index) => {

                if (value.quantiDias < 10) {
                    return (
                        <Card key={Date.now() + index} id='card-home-fatura'>
                            <CardHeader>

                             

                                <FiberManualRecordIcon color={gerarInconepagamento(value.quantiDias)} />


                                {/* { <FiberManualRecordIcon color={value.vencida ? 'error' : 'warning' || value.quantiDias < 15 ? 'success' : ''} />} */}
                                {value.nomeEmpresa}
                            </CardHeader>
                            <CardBody>

                                <div>
                                    {value.status}
                                </div>

                                <div style={{ fontSize: '.8rem' }}>
                                    Vencimento: {value.fatura}
                                    {` ${value.valor}`}
                                </div>

                                {newTextZap && <span id='enviarCobranca' onClick={() => enviarCobranca(value)} >
                                    Enviar cobrança <WhatsAppIcon />
                                </span>}



                            </CardBody>
                        </Card>
                    )

                }


            })}




        </div>
    )
}