import './marcasQueConfiam.css'


export default function MarcasQueConfiam({ dadosEmpresa }) {

    function hexToRgba(hex, alpha = 1) {
        let r = 0, g = 0, b = 0;


        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }

        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }


        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return (
        <>

            <div id="containerMarcasQueConfiam" style={{ backgroundColor: '#fff' || hexToRgba(dadosEmpresa?.corSelecionada, 0.07) }}>
                <div id="TituloPlano" className="nossosNumeros" style={{ color: dadosEmpresa?.corSelecionada }}>
                    {`Marcas que confiam na ${dadosEmpresa.nomeEmpresaProposta}`}
                </div>

                <div id="boxLogoEmpresas">
                    {dadosEmpresa?.marcasClientes.map((value) => {

                        return (
                            <div key={value} className="logoEmpresaQueConfiam">
                                <div className="imgBgLogo" style={{ backgroundImage: `url(${value})` }}>
                                    
                                </div>

                            </div>
                        )

                    })}

                </div>

            </div>

        </>
    )
}