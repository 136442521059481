import moment from 'moment'
import './listarAnunciantes.css'



export default function ListarAnunciantes({ bodyTerminal, bodyVendas }) {



    function verificarAnunciantes() {

        const nomeTerminal = bodyTerminal.nomeEmpresa.toUpperCase()
        const TerminalID = bodyTerminal._id
        let anuncianteNoTErminal = []



        bodyVendas.map((value) => {

            if (value.status === 'ativo') {

                value.terminalOption.map((val) => {

                    let terminal_id = val.split('-')[1]

                    anuncianteNoTErminal.push({
                        terminal: terminal_id,
                        anuciante: value.nomeEmpresa,
                        fim_Vinculacao: value.fim_Vinculacao,
                        inicioVinculacao: moment(value.inicioVinculacao).format('DD/MM/YYYY')
                    })
                })
            }


        })        


        let listaAnuciante = []

        anuncianteNoTErminal.map((value) => {

            if (value.terminal === TerminalID) {
                listaAnuciante.push(value)
            }


        })


        return listaAnuciante
    }



    return (
        <>

            {verificarAnunciantes().map((value, ind) => {
                return (

                    <div className='lista-anunciantes' key={ind}>

                        <span style={{ fontWeight: '600' }}>{value.anuciante}: </span>
                        <span style={{ fontSize: '.9rem' }}> {`${value.inicioVinculacao} até ${value.fim_Vinculacao}`}</span>

                    </div>
                )
            })}
        </>
    )
}


