import React from 'react';

import './index.css';
import App from './App';
import { createRoot } from 'react-dom/client';

import { UserProvider } from './context/UserContext'

//router
import { RouterProvider, createBrowserRouter } from "react-router-dom";

//page
import Home from './page/home/home'

import NovoAnuncio from './page/novoAnuncio/novoAnuncio'
import EditarAnucio from './page/novoAnuncio/ditarAnuncio'

import ListarClientes from './page/clientes/ListarClientes/listarClientes'
import CadastrarCliente from './page/clientes/cadastrarCliente/CadastrarCliente'

import ListarPlanos from './page/planos/ListarPlanoss/listarPlanos'
import CadastrarPlano from './page/planos/cadastrarPlanos/CadastrarPlano'

import ListarTerminais from './page/terminal/ListarTerminais/listarTerminal'
import CadastrarTerminal from './page/terminal/cadastrarTerminal/CadastrarTerminal'
import TrerminalCadastrado from './page/terminal/terminalCadastrado/terminalCadastrado'

import ListarAnuncios from './page/finaceiro/anuncios/anuncios'
import Caixa from './page/finaceiro/caixa/caixa'

import Login from './page/login/login'

import Proposta from './page/proposta/proposta'

import Error from './page/error/error';
import Settings from './page/settings/settings';






//Rotas paginas
const router = createBrowserRouter([
  {

    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/listar-clientes",
        element: <ListarClientes />
      },
      {
        path: "/cadastrar-cliente",
        element: <CadastrarCliente />
      },
      {
        path: "/novo-anuncio",
        element: <NovoAnuncio />
      },
      {
        path: "/listar-planos",
        element: <ListarPlanos />
      },
      {
        path: "/cadastrar-plano",
        element: <CadastrarPlano />
      },
      {
        path: "/listar-terminais",
        element: <ListarTerminais />
      },
      {
        path: "/cadastrar-terminal",
        element: <CadastrarTerminal />
      },
      {
        path: "/listar-anuncios",
        element: <ListarAnuncios />
      }
      ,
      {
        path: "/terminal-cadastrado",
        element: <TrerminalCadastrado />
      }
      ,
      {
        path: "/caixa",
        element: <Caixa />
      },
      {
        path: "/editar-anuncio",
        element: <EditarAnucio />
      } ,
      {
        path: "/configuracoes",
        element: <Settings />
      } ,      
       {
        path: "*",
        element: <Error />
      } 


    ]
  }
  , {
    element: <Login />,
    children: [
      {
        path: "/login",
        element: <Login />
      },]
  }
  , {
    element: <Proposta />,
    children: [
      {
        path: "/proposta-comercial",        
      },]
  }

])



const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(

  <UserProvider>
    <RouterProvider router={router} />
  </UserProvider>




);

//reportWebVitals(sendToVercelAnalytics);
