



export default function BoxConfig({ icone, titulo, texto, toggle,setTipo }) {
    return (
        <>
            <div className="container-conf" onClick={() => {
                toggle()
                setTipo(titulo)
            }}>
                <div className="icone-config">
                    {icone}
                </div>
                <div className="texto-conf">
                    <div className="titulo-um">{titulo}</div>
                    <div className="texto-conf-info">{texto}</div>
                </div>
            </div>
        </>
    )
}