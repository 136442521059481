import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import './modeloSite.css'
import ModeloProposta from './modeloProposta';



export default function ModeloSite({ dadosEmpresa, videobg, setProposta }) {




    return (
        <div id="modeloSite">

            <div className="boxModleoTopo">

                <div className={`topoPropostaModelo`}
                    style={
                        {
                            backgroundColor: !dadosEmpresa?.habilitarBtn ? dadosEmpresa?.corSelecionada : null
                        }
                    }>
                    <div className="nomeEmpresaProposta" style={{ color: '#fff' }}>
                        {dadosEmpresa?.nomeEmpresaProposta}
                    </div>

                    <div className="contatoPropostaTopo">

                        <div className="zapProposta">
                            <a target="_blank" >
                                <WhatsAppIcon style={{ color: '#fff' }} />
                            </a>
                        </div>
                        <div className="instamProposta">
                            <a target="_blank" >
                                <InstagramIcon style={{ color: '#fff' }} />
                            </a>
                        </div>

                    </div>
                </div>

                <div className="vidBgModelo">
                    {
                        dadosEmpresa?.habilitarBtn &&
                        <video
                            autoPlay
                            muted
                            loop
                            type="video/mp4" src={require(`../../../videos/background/bgVid_${dadosEmpresa?.videoBg}.mp4`)}>
                        </video>
                    }
                </div>

                <div className={`textModelo ${dadosEmpresa?.habilitarBtn ? 'modleoComVid' : 'modleosemVid'}`}>
                    <div className={`tituloModeloUm ${!dadosEmpresa?.habilitarBtn ? 'textCenter' : ''}`}
                        onChange={(e) => {
                            setProposta((data) => {
                                return (
                                    {
                                        ...data,
                                        tituloPrimario: e.target.value
                                    }
                                )
                            })
                        }}>{dadosEmpresa?.tituloPrimario}</div>
                    <div className={`tituloModeloDois ${!dadosEmpresa?.habilitarBtn ? 'textCenter' : ''}`}
                        onChange={(e) => {
                            setProposta((data) => {
                                return (
                                    {
                                        ...data,
                                        tituloSecundario: e.target.value
                                    }
                                )
                            })
                        }}>{dadosEmpresa?.tituloSecundario}</div>
                </div>

            </div>


            <div className="planosModelo" style={{ color: dadosEmpresa?.corSelecionada }}>Planos</div>

            <div className="modeloPropostaCard">
                <ModeloProposta dadosEmpresa={dadosEmpresa} />
                <ModeloProposta dadosEmpresa={dadosEmpresa} />
            </div>


        </div>
    )
}