


import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';



export default function CorPrincipal({ atual, setProposta,tipo }) {


  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(atual);


  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color.hex);
    setProposta((data) => {
      return {
        ...data,
        [tipo]: color.hex
      }
    })
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '50px',
        height: '25px',
        borderRadius: '2px',
        background: color,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        width: '100%'
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <>
      <div style={styles.swatch} onClick={handleClick}>
        <div className='corBtn' style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div  style={styles.cover} onClick={handleClose} />
          <SketchPicker  color={color} onChange={handleChange} />
        </div>
      ) : null}
    </>
  );
};


