
import './backgroundVideo.css'



import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

export default function BackgroundVideo({ Proposta, setProposta, getVideos }) {

    let bodyVideos = []
   
    for (let x = 1; x <= 12; x++) {

        bodyVideos.push(require(`../../../videos/background/bgVid_${x}.mp4`))


    }


    
    return (
        <>
            <div className="modeloVideo">
                {bodyVideos.map((val, ind) => {

                    return (
                        <div key={ind} className="videoModleoLoop"
                            onClick={(e) => {

                                setProposta((data) => {
                                    return {
                                        ...data,
                                        videoBg: ind + 1,
                                        vidEscolhido: val
                                    }
                                })
                            }}>
                            <video preload='auto'  controls muted loop type="video/mp4" src={`${val}`}></video>

                            <div className={`iconeVidAtivo iconeVid ${Proposta.videoBg === ind + 1 ? '' : 'hideDisplay'}`}>
                                <CheckCircleIcon />
                            </div>

                            <div className={`iconeVidInativo iconeVid ${Proposta.videoBg !== ind + 1 ? '' : 'hideDisplay'}`}>
                                <DoNotDisturbIcon />
                            </div>

                            

                        </div>

                    )

                })}
            </div>
        </>
    )
}