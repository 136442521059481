import { useEffect } from "react";


export function ScrollOuvinte(setAtTop, value){

    useEffect(() => {
        const handleScroll = () => {
            // Define como verdadeiro se o scroll estiver no topo
            setAtTop(window.scrollY > value);
        };

        // Adiciona o event listener ao scroll
        window.addEventListener('scroll', handleScroll);

        // Limpeza: remove o event listener quando o componente é desmontado
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
}