
import '../../../style/pageForaDoModal.css'
import './cardTerminal.css'
import './listarTerminal.css'

import React, { useEffect, useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
    Progress, Alert,  Form, Col, Label
    , Input, FormGroup, Modal, ModalBody, ModalHeader
} from 'reactstrap';



//Retirar caracteres especial 
import { RemoverCaracteres } from '../../../util/tools'



//função para buscar e filtrar buscas 
import { SearchFilter, PrintCard } from '../../../util/searchFilter'
import ListarAnunciantes from '../listarAnucinatesdoTErminal/listarAnunciantes';


import { HeadersGet } from '../../../util/headers';

//context todos os dados da api
import { UserContext } from '../../../context/UserContext';
import { UrlServer } from '../../../util/conect';
//const { dadosAPI, setDadosAPI} = useContext(UserContext)

export default function Planos() {

    const { dadosAPI, setDadosAPI} = useContext(UserContext)

    const [modal, setModal] = useState({abrir: false, body: ''});
    const toggle = () => setModal((data) => {
        return { ...data, abrir: !data.abrir}
    });

    const [getDados, setDados] = useState()

    //Fetch
    const [fetchErro, setfetchErro] = useState({ res: true, tipo: 'carregando', msg: '' })
    const [dadosFetch, setdadosFetch] = useState(false)

    //fetch dados vendas buscar clientes
    const [getDadosVendas, setDadosVendas] = useState()

    //avançar pagina /terminal-cadastrado
    const [avanca, setavanca] = useState(false)

    //buscar
    const [buscarCliente, setbuscarCliente] = useState('')

    

    useEffect(  () => {

        if (!dadosAPI?.buscarTerminaisTerminal && !dadosAPI?.buscarVendaTerminal) {         

        fetch(`${UrlServer()}/buscar-terminais/${1}`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.connected) {

                    data.response.sort((a, b) => a.nomeEmpresa
                        .localeCompare(b.nomeEmpresa, 'pt', { sensitivity: 'base' }));

                    setdadosFetch(data.response)


                    //colocar todos os dados da API no context
                    setDadosAPI((dadosAPI) => {
                        return {
                            ...dadosAPI,
                            buscarTerminaisTerminal: data.response
                        }
                    })

                    setfetchErro((err) => {
                        return {
                            ...err,
                            res: false
                        }
                    })
                } else {
                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: data.response
                        }
                    })
                }

            })
            .catch((error) => {
               
                setfetchErro((err) => {
                    return {
                        ...err,
                        tipo: 'error',
                        msg: "Erro interno"
                    }
                })

            });


        fetch(`${UrlServer()}/buscar-venda`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.connected) {

                    setDadosVendas(data.response)

                    //colocar todos os dados da API no context
                    setDadosAPI((dadosAPI) => {
                        return {
                            ...dadosAPI,
                            buscarVendaTerminal: data.response
                        }
                    })

                   
                    setfetchErro((err) => {
                        return {
                            ...err,
                            res: false
                        }
                    })
                } else {
                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: data.response
                        }
                    })
                }

            })
            .catch((error) => {
               
                setfetchErro((err) => {
                    return {
                        ...err,
                        tipo: 'error',
                        msg: "Erro interno"
                    }
                })

            })

        } else{

            setdadosFetch(dadosAPI.buscarTerminaisTerminal)
            setDadosVendas(dadosAPI.buscarVendaTerminal)

            setfetchErro((err) => {
                return {
                    ...err,
                    res: false
                }
            })
            
        }


    }, [])

    if (fetchErro.res) {
        if (fetchErro.tipo === 'carregando') {
            return (
                <div className='stk-body'>
                    <div className="carregando-fetch" style={{ paddingBottom: '10px' }}>
                        <Progress
                            animated
                            value={100}
                            color="warning"
                        />
                    </div>
                </div>
            )
        } else if (fetchErro.tipo === 'error') {
            return (
                <div className='stk-body'>
                    <div className="box-shadow-error" style={{ paddingBottom: '10px' }}>
                        <Alert color="danger">
                            Não foi possivel se conectar com oservidor.
                            <br></br>
                            Tente novamente mais tarde.
                            <br></br>
                            <code style={{ fontSize: '.7rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>
                </div>
            )
        }
    }

   
    if (dadosFetch.length === 0) {
        return (
            <>
                <div className="stk-body">

                    <Alert color="danger" isOpen={true} >
                        Necessário cadastrar um terminal primeiro.
                        <br></br>
                        <Link to="/cadastrar-terminal"> Clique aqui para cadastrar.</Link>
                    </Alert>

                </div>
            </>
        )
    }

    if(dadosFetch){
return (
        <>

            <div id='planos-body' className='stk-body'>
                <div className="titulo-um">
                    Terminais cadastrados
                </div>

                <div className="box-shadow">
                    <div className="box-busca">
                        <Form>

                            <Col>
                                <FormGroup>
                                    <Label for="plano">
                                        Buscar terminal:
                                    </Label>
                                    <Input
                                        className='icone-input-buscar'
                                        id="buscarCliente"
                                        name="buscarCliente"
                                        type="search"
                                        placeholder='Buscar terminal'
                                        onChange={(e) => {
                                            setbuscarCliente(RemoverCaracteres(e.target.value))


                                        }}

                                    />
                                </FormGroup>
                            </Col>

                            <FormGroup>
                            </FormGroup>
                        </Form>
                    </div>

                </div>

                <div className="box-shadow">
                    <div className="box-empresa">


                        {buscarCliente.length > 0 ? (
                            SearchFilter(dadosFetch, buscarCliente, buscarCliente, 'nomeEmpresa').map((body, ind) => {
                                return (
                                    <span key={ind}>
                                        {PrintCard(body, setDados, setModal, modal, `cadastrar-terminal?terminal=${buscarCliente}`, 'terminal',getDadosVendas,setavanca)}

                                    </span>
                                )
                            })
                        ) :
                            (

                                dadosFetch?.map((body, ind) => {
                                    return (
                                        <span key={ind}>
                                            {PrintCard(body, setDados, setModal, modal, null, 'terminal',getDadosVendas,setavanca)}

                                        </span>
                                    )

                                })
                            )
                        }




                    </div>
                </div>
            </div> 

            {avanca && <Navigate to='/terminal-cadastrado' state={{ terminal: getDados, vendas: getDadosVendas }} />}

            <Modal isOpen={modal.abrir} toggle={toggle} >
                <ModalHeader toggle={toggle}>{getDados?.nomeEmpresa || ''}</ModalHeader>
                <ModalBody>
                  <ListarAnunciantes bodyTerminal={getDados} bodyVendas={modal.body}/>
                </ModalBody>
            </Modal>           

        </>
    )
    }

    
}
