
import './estatisticas.css'

import IndexContador from '../contador/indexContador'

export default function Estatisticas({ dadosEmpresa }) {

 
  
    return (
        <div id="ContainerEstatisticas" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}>

            <div className="boxEstatisticasUm" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}>
                <span className='textEstatisticas textEstUm' >Mais de</span>
                <span className='textEstatisticas textEstQuant'>

                    <IndexContador alvo={dadosEmpresa?.quantInsercoes ? dadosEmpresa?.quantInsercoes : 0} count={.5} />
                </span>
                <span className='textEstatisticas textEstDois'>inserções por mês</span>
            </div>

            <div className="boxEstatisticasDois" style={
                { 
                    backgroundImage: `url(${dadosEmpresa?.insercoesMes})`,
                    opacity: ".7",
                    backgroundSize: dadosEmpresa.ajusteImgExtra
                }
                } >

            </div>

        </div>

    )
}


