

import './CompAlert.css'


import React, { useState } from 'react';
import {
    Alert
} from 'reactstrap';

export default function CompALert({
    
    visibleCompAlert,
    setVisibleCompAlert,
    color, toggle, link }) {

    
    const onDismiss = () => setVisibleCompAlert((val) => !val);

    return (
        <>
            <div id="comp-alert">
                <Alert color="danger" isOpen={visibleCompAlert.open} toggle={onDismiss}>

                    {visibleCompAlert.msg}

                </Alert>
            </div>
        </>
    )
}