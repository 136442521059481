import './datapicker.css'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns/esm';



const Datapicker = ({setMesAno}) => {  
   
  //selected={new Date()}
  
    return (
      <div className='fixed-datepicker-container'>
       
        <DatePicker
        className='input-picker'
          
          onChange={(e) => setMesAno(format(e, "MM/yyyy"))}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          locale={ptBR}
          open={true}
        />
      </div>
    );
  };
  
  export default Datapicker;