import './caixa.css'

import React, { useEffect, useState } from 'react';

//função HeadersGet
import { HeadersGet } from '../../../util/headers'

import Datapicker from './datapicker'

import { Link } from 'react-router-dom';
import {
    Progress, Alert, Modal, ModalHeader, ModalBody, Form, Col, Label
    , Input, FormGroup, Button
} from 'reactstrap';


import CardFiltroMes from './cardFiltroMes'

import moment from 'moment';
import { UrlServer } from '../../../util/conect';



const gerarDataMY = moment().format('MM/YYYY')
moment.locale('pt');
// mudar os nomes dos meses para o locale "pt"
moment.updateLocale('pt', {
    months: [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
        "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]
});

export default function Caixa() {



    //guarda o mes e o ano do input datapicker
    const [mesAno, setMesAno] = useState(gerarDataMY)

    //Fetch
    const [fetchErro, setfetchErro] = useState({ res: true, tipo: 'carregando', msg: '' })
    const [dadosFetch, setdadosFetch] = useState(false)


    const getMesAtual = new Date().getMonth() + 1


    useEffect(() => {

        fetch(`${UrlServer()}/buscar-venda`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.connected) {

                    //ordem alfabetica
                    // data.response.sort((a, b) => a.nomeEmpresa
                    //     .localeCompare(b.nomeEmpresa, 'pt', { sensitivity: 'base' }));

                    setdadosFetch(data.response)
                    setfetchErro((err) => {
                        return {
                            ...err,
                            res: false
                        }
                    })
                } else {
                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: data.response
                        }
                    })
                }

            })
            .catch((error) => {
                let errorString = new String(error)
                setfetchErro((err) => {
                    return {
                        ...err,
                        tipo: 'error',
                        msg: errorString
                    }
                })

            })
    }, [])



    if (fetchErro.res) {
        if (fetchErro.tipo === 'carregando') {
            return (
                <div className='stk-body'>
                    <div className="carregando-fetch" style={{ paddingBottom: '10px' }}>
                        <Progress
                            animated
                            value={100}
                            color="warning"
                        />
                    </div>
                </div>
            )
        } else if (fetchErro.tipo === 'error') {
            return (
                <div className='stk-body'>
                    <div className="box-shadow-error" style={{ paddingBottom: '10px' }}>
                        <Alert color="danger">
                            Não foi possivel se conectar com oservidor.
                            <br></br>
                            Tente novamente mais tarde.
                            <br></br>
                            <code style={{ fontSize: '.7rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>
                </div>
            )
        }
    }


    if (dadosFetch.length === 0) {
        return (
            <>
                <div className="stk-body">

                    <Alert color="danger" isOpen={true} >
                        Necessário cadastrar um anúncio primeiro.
                        <br></br>
                        <Link to="/novo-anuncio"> Clique aqui para cadastrar.</Link>
                    </Alert>

                </div>
            </>
        )
    }


    function MensalData(data) {

        const getDate = (`01/${data}`).split('/').reverse().join('-')

        return moment(getDate).format('MMMM')
    }

    function gerarDataSistema() {

        const getMesf = new Date().getMonth() + 1
        const getAnof = new Date().getFullYear()

        return `${getMesf}/${getAnof}`
    }

    function quantidadeAnunciosMes(dataRecebida) {

        let quantidadeAnuncioMes = []     

        let quantidadeAnunciosAno = []
        let valorRecebidosAno = []
        let valorAreceberAno = []
        let dataAno


        dadosFetch.forEach((value) => {

            const getDate = moment(value.inicioVinculacao).format('MM/YYYY')
            const anoVinculacao = getDate.split('/')[1]
            const dataRecebidaSplit = dataRecebida.split('/')[1]
            dataAno = dataRecebidaSplit

            if (getDate === dataRecebida) {
                quantidadeAnuncioMes.push(value)
            }

           



            //calcular valor ano
            if (anoVinculacao === dataRecebidaSplit) {

                quantidadeAnunciosAno.push(dadosFetch._id)

            }
            value.fatura.forEach((fa) => {


                if (fa.pago) {

                    if (Number(fa.data.split('/')[2]) === Number(dataRecebida.split('/')[1])) {

                       
                        valorRecebidosAno.push({ ...fa, valor: value.valor })

                    }

                } else {

                    if (Number(fa.data.split('/')[2]) === Number(dataRecebida.split('/')[1])) {

                       
                        valorAreceberAno.push({ ...fa, valor: value.valor })

                    }
                   
                }

            })




        })



       

        const totalRecebidosAno = valorRecebidosAno.reduce((a, b) => a + b.valor, 0)
        const totalAreceberAno = valorAreceberAno.reduce((a, b) => a + b.valor, 0)
        const totalAno = totalAreceberAno + totalRecebidosAno

       
        

        return {
            quantAnuncioMes: quantidadeAnuncioMes.length, //           
            mes: MensalData(dataRecebida), //
            totalAno: { totalRecebidosAno, totalAreceberAno, dataAno, totalAno, quantidadeAnunciosAno: quantidadeAnunciosAno.length } //
        }
    }


    function gerarRecebidosMes(dataRecebida) {


        let faturasPagas = []
        let faturaAPagar = []
        const mesAtual = dataRecebida.split('/')[0]
        const anoAtual = dataRecebida.split('/')[1]



        dadosFetch.map((value) => {

            value.fatura.map((val) => {

                if (val.pago) {


                    if (Number(val.data.split('/')[2]) === Number(anoAtual)) {

                        if (Number(val.data.split('/')[1]) === Number(mesAtual)) {

                            
                            faturasPagas.push(value.valor)
                        }
                    }


                }

                if (!val.pago) {

                    if (Number(val.data.split('/')[2]) === Number(anoAtual)) {

                        if (Number(val.data.split('/')[1]) === Number(mesAtual)) {
                           
                            faturaAPagar.push(value.valor)

                        }
                    }


                }

            })

        })

        
        const pago = faturasPagas.reduce((a, b) => a + b, 0)
        const aPagar = faturaAPagar.reduce((a, b) => a + b, 0)
        const total = pago + aPagar



        return { pago, aPagar, total }
    }



    return (
        <>
            <div id="container-caixa">
                <div className="stk-body">




                     {<CardFiltroMes
                        mes={quantidadeAnunciosMes(gerarDataSistema()).mes}
                        quantAnuncioMes={quantidadeAnunciosMes(gerarDataSistema()).quantAnuncioMes}
                        totalAreceber={gerarRecebidosMes(gerarDataSistema()).aPagar}
                        totalMes={gerarRecebidosMes(gerarDataSistema()).total}
                        totalRecebido={gerarRecebidosMes(gerarDataSistema()).pago}
                        faturamentoAno={false}

                    />}


                    <div className="titulo-um" style={{ margin: '10px 0 5px 0' }}>Filtrar por mês/ano</div>

                    <div className="fatura-filtro">
                        <div className="box-card-fatura data-picker">

                            <Datapicker setMesAno={setMesAno} />
                        </div>
                        <div className="box-card-fatura">                            

                           {<CardFiltroMes
                                mes={quantidadeAnunciosMes(mesAno).mes}
                                quantAnuncioMes={quantidadeAnunciosMes(mesAno).quantAnuncioMes}
                                totalAreceber={gerarRecebidosMes(mesAno).aPagar}
                                totalMes={gerarRecebidosMes(mesAno).total}
                                totalRecebido={gerarRecebidosMes(mesAno).pago}
                                faturamentoAno={false}

                            />} 
                        </div>


                        <div className="box-card-fatura">
                            <div className="titulo-um" style={{ margin: '10px 0 5px 0' }}>Filtrar por ano</div>

                            <CardFiltroMes
                                faturamentoAno={quantidadeAnunciosMes(mesAno).totalAno}
                            />
                        </div>


                    </div>




                </div>
            </div>

        </>
    )
}
