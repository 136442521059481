
import './home.css'
import React, { useState } from 'react';
import { Alert } from 'reactstrap';


//função HeadersGet
import { HeadersGet } from '../../util/headers'

import CardHome from './cardHome'

import GraficoBAr from './graficoBar';

import { useEffect } from 'react';
import { PrecoBrl } from '../../util/precoBrl';
import moment from 'moment';
import { UrlServer } from '../../util/conect';



export default function Home() {





    const [dadoBar, setDadosBar] = useState({});

    const [getFAturas, setFaturas] = useState();
    const [dadosCliente, setDadosCliente] = useState();
    const [visible, setVisible] = useState(true);

    const onDismiss = () => setVisible(false);



    useEffect(() => {


        fetch(`${UrlServer()}/dados-terminal`, HeadersGet())
            .then(response => response.json())
            .then(data => {



                setFaturas(data.response.body)
                setDadosCliente(data.response.dadosCliente)

                setDadosBar((body) => {
                    return {
                        ...body,
                        terminal: data.response.terminal,
                        anunciantes: data.response.anunciantes,
                    }
                })
            })
            .catch((error) => {


            })
    }, [])


    function gerarFaturas() {
        let faturas = []
        let clienteContato = 'zap'

        getFAturas?.map((value) => {



            if (value.status === "ativo") {


                dadosCliente.forEach((idCliente) => {

                    if (idCliente._id === value.id_cliente) {
                        clienteContato = idCliente.whatsApp
                    }

                })


                value.fatura.map((val) => {


                    if (!val.pago) {

                        const diaAtual = new Date().getDate()
                        const mesAtual = new Date().getMonth() + 1
                        const anoAtual = Number(new Date().getFullYear())

                        const diaFAtura = val.data.split('/')[0]
                        const mesFAtura = val.data.split('/')[1]
                        const anoFatura = Number(val.data.split('/')[2])



                        let status = ''
                        let diaDias
                        let vencida
                        let quantiDias



                        if (anoAtual === anoFatura) {



                            if (Number(mesFAtura) <= Number(mesAtual)) {

                                const GetdataFatura = new Date(val.data.split('/').reverse().join('/'))
                                const gerarDataTAual = new Date(`${anoAtual}/${mesAtual}/${diaAtual}`)



                                if (GetdataFatura < gerarDataTAual) {

                                    
                                    const data1 = moment(GetdataFatura, 'YYYY-MM-DD');
                                    const data2 = moment(gerarDataTAual, 'YYYY-MM-DD');

                                    const diferencaEmDias = data2.diff(data1, 'days');
                                   
                                   

                                    faturas.push({
                                        nomeEmpresa: value.nomeEmpresa,
                                        valor: PrecoBrl(value.valor),
                                        fatura: val.data,
                                        status: `Fatura venceu há ${diferencaEmDias} dias`,
                                        whatsApp: clienteContato,
                                        vencida: false,
                                        quantiDias: 0
                                    })
                                }




                                if ((Number(diaFAtura)) >= Number(diaAtual)) {

                                    const subDias = (Number(diaFAtura)) - Number(diaAtual)
                                    
                                    vencida = false

                                    if (subDias > 1) {
                                        diaDias = 'em ' + subDias + ' dias'
                                    } else if (subDias === 1) {
                                        diaDias = 'em ' + subDias + ' dia'
                                    } else if (subDias === 0) {
                                        diaDias = 'hoje'
                                    }

                                    quantiDias = subDias
                                    status = `Fatura vence ${diaDias} `
                                }

                                if ((Number(diaFAtura)) < Number(diaAtual)) {
                                    const subDias = Number(diaAtual) - Number(diaFAtura)
                                    
                                    vencida = true

                                    if (subDias > 1) {
                                        diaDias = 'há ' + subDias + ' dias'
                                    } else if (subDias === 1) {
                                        diaDias = 'há ' + subDias + ' dia'
                                    }

                                    quantiDias = subDias
                                    status = `Fatura venceu ${diaDias} `
                                }

                                
                                let proseguir
                                faturas.map((v) => {

                                    if (v.nomeEmpresa === value.nomeEmpresa && v.fatura === val.data) {
                                        proseguir = true
                                    }

                                })

                                if (!proseguir) {
                                    faturas.push({
                                        nomeEmpresa: value.nomeEmpresa,
                                        valor: PrecoBrl(value.valor),
                                        fatura: val.data,
                                        status: status,
                                        whatsApp: clienteContato,
                                        vencida,
                                        quantiDias
                                    })

                                   
                                }


                            }
                        }
                    }
                })
            }

        })
       

        faturas.sort((a, b) => a.quantiDias - b.quantiDias);

    
        return faturas
    }



    return (

        <>

            <div className="stk-body">



                <Alert color="info" isOpen={visible} toggle={onDismiss}>
                    Informações do Sistema.
                    <br></br>
                    <code>
                        Versão beta 1.00
                        <span style={{fontSize: '.7rem'}}>  
                        <br></br>                      
                        Poderá ocorrer eventual
                        lentidão nas conexões com
                        o servidor em alguns momentos.
                        </span>
                    </code>
                </Alert>
                <div id='home-page'>

                    <div className="box-grafico">

                        <div className="box-graf">
                            <div className="titulo-um">Terminais</div>
                            <div className="box-shadow">
                                <GraficoBAr dadoBar={dadoBar.terminal} titulo={'Anunciantes'} />
                            </div>
                        </div>

                        <div className="box-graf">

                            <div className="titulo-um"> Anunciantes</div>
                            <div className="box-shadow">
                                <GraficoBAr dadoBar={dadoBar.anunciantes} titulo={'Vendas'} />
                            </div>
                        </div>



                    </div>
                </div>

                {getFAturas && <CardHome body={gerarFaturas} />}
            </div>
        </>


    )
}