import './cliente.css'

import React, { useEffect, useState } from 'react';

import {
    Button, Input, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, Progress, Alert,
    Accordion, AccordionBody, AccordionHeader, AccordionItem,
} from 'reactstrap';

import moment from 'moment/moment';

//função HeadersGet
import { HeadersGet } from '../../../util/headers'

//importar função fetch
import { ConectURL, UrlServer } from '../../../util/conect'



//Retirar caracteres especial 
import { RemoverCaracteres } from '../../../util/tools'

import { PrecoBrl } from '../../../util/precoBrl'



function formarData(data) {

    return moment(data).format('DD/MM/YYYY')

}


export default function Cliente({ dados }) {


    const [vendasCtx, setVendasCtx] = useState()



    //confirmação alerta info
    const [visibleCompAlert, setVisibleCompAlert] = useState(false);
    const [msgAlert, setMsgAlert] = useState();



    //todos os dados do Form
    const [getContrato, setContrato] = useState(
        { ...dados }
    );


    //carregando dados recebido do fetch
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })
    const [dadosFetch, setdadosFetch] = useState(false)

    //alerta fim do update alertToggle
    const [alertTg, setAlertTg] = useState(true);



    //Accordion dados empresa
    const [open, setOpen] = useState('0');
    const toggle = (id) => {

        if (open === id) {
            setOpen('0');
        } else {
            setOpen(id);
        }
    };

    //Accordion historico
    const [openHist, setOpenHist] = useState('1');
    const toggleHist = (id) => {

        if (openHist === id) {
            setOpenHist('0');
        } else {
            setOpenHist(id);
        }
    };

    function alertToggle() {
        setAlertTg((to) => !to)
        setfetchErro((body) => {
            return {
                ...body,
                btn: false
            }
        })
    }




    function verificarFetch() {

        if (fetchErro.res) {

            if (fetchErro.tipo === 'carregando') {
                return (

                    <Progress
                        animated
                        value={100}
                        color="warning"
                    />

                )
            } else if (fetchErro.tipo === 'error') {

                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert color="danger">
                            <code style={{ fontSize: '.8rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>

                )
            } else if (fetchErro.tipo === 'sucesso') {

                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert
                            isOpen={alertTg}
                            toggle={alertToggle}
                            color={fetchErro.metodoFetch === 'DELETE' ? 'danger' : 'success'}
                            onClick={() => {

                                window.location.reload()

                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                {fetchErro.msg}
                            </span>

                        </Alert>
                    </div>

                )
            }
        }
    }


    //vrificar confirmação
    function CompConfimacao(visibleCompAlert, setVisibleCompAlert) {
        return (
            <>



                <div id="comp-alert-confirm">
                    <Alert color="danger" isOpen={visibleCompAlert} style={{ maxWidth: '300px' }}>

                        {msgAlert.msg}

                        <div id='btn-alert-confirm'>

                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => {
                                    setVisibleCompAlert(false)


                                }}
                            >
                                Não
                            </Button>
                            <Button
                                color="success"
                                size="sm"
                                onClick={() => {

                                    setVisibleCompAlert(false)


                                    msgAlert.tipo === 'deletar' ?

                                        ConectURL(
                                            'DELETE',
                                            'editar-cliente',
                                            'Cliente deletado',
                                            null,
                                            setfetchErro,
                                            getContrato._id
                                        )
                                        :
                                        ConectURL(
                                            'PATCH',
                                            'editar-cliente',
                                            'Cliente editado',
                                            getContrato,
                                            setfetchErro,
                                            getContrato._id,

                                        )

                                }}
                            >
                                Sim
                            </Button>


                        </div>

                    </Alert>
                </div>
            </>
        )
    }



    useEffect(() => {
        fetch(`${UrlServer()}/buscar-venda`, HeadersGet())
            .then(response => response.json())
            .then(data => {


                setVendasCtx(data)

                let anuncios = []
                if (data.connected) {

                    data.response.map((value) => {

                        if (value.id_cliente === dados._id) {
                            anuncios.push(value)
                        }
                    })

                    setdadosFetch(anuncios)

                    setfetchErro((err) => {
                        return {
                            ...err,
                            res: false
                        }
                    })
                } else {
                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: data.response
                        }
                    })
                }

            })
            .catch((error) => {
                let errorString = new String(error)
                setfetchErro((err) => {
                    return {
                        ...err,
                        tipo: 'error',
                        msg: errorString
                    }
                })

            })
    }, [])


    function gerarHistorico() {

        if (dadosFetch) {

            let somar = 0;

            return dadosFetch.map((value, i) => {

                value.fatura.forEach(faturas => {
                    if (faturas.pago) {
                        // setFaturamento(faturas)
                        somar += value.valor
                    }
                });

                return (
                    <div key={Date.now() + i}>
                        <AccordionItem>
                            <AccordionHeader targetId={String(i + 1)}>{`${formarData(value.inicioVinculacao)} - ${value.plano}`}</AccordionHeader>
                            <AccordionBody accordionId={String(i + 1)}>
                                <div className='lista-historico'> Status: {`${value.status}`}</div>
                                <div className='lista-historico'> Contrato: {`${value.contratacao} `}</div>
                                <div className='lista-historico'> Campanha: {`${formarData(value.inicioVinculacao)} até ${value.fim_Vinculacao}`}</div>
                                <div className='lista-historico'> Valor: {`${PrecoBrl(value.valor)}/${value.formPagemnto.split('-')[0]}`}</div>
                                <div className='lista-historico'> Terminal: {value.terminalOption.map(val => `${val.split('-')[0]} / `)}</div>

                            </AccordionBody>
                        </AccordionItem>
                    </div>
                )
            })


        }

    }


    function gerarValores() {

        if (dadosFetch) {


            let aReceber = 0;
            let recebidos = 0

            dadosFetch.map((value, i) => {

                value.fatura.forEach(faturas => {
                    if (faturas.pago) {
                        // setFaturamento(faturas)
                        recebidos += value.valor
                    }
                    if (!faturas.pago) {
                        // setFaturamento(faturas)
                        aReceber += value.valor
                    }
                });


            })


            return { aReceber, recebidos }
        }

    }

    //verificar se o cliente já esta com anuncio ativo bloquenado o delatr
    function compareID() {

        if (getContrato._id && vendasCtx?.response) {

            const nomeRecebidoContrato = getContrato._id
            const nomeRecebidoContext = vendasCtx.response

            //id_cliente
            const array = nomeRecebidoContext.map((val) => {
                return val.id_cliente
            })



            return array.includes(nomeRecebidoContrato)
        }




    }


    return (


        <div id='anunciante-body' >


            <Accordion open={open} toggle={toggle}>
                <AccordionItem>

                    <AccordionHeader targetId="1">Editar dados da Empresa</AccordionHeader>
                    <AccordionBody accordionId="1">

                        <div className="box-shadoww">

                            <Form id='dadosDaEmpresa'>


                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="nomeEmpresa">
                                                Nome:
                                            </Label>
                                            <Input
                                                disabled={compareID()}
                                                id="nomeEmpresa"
                                                name="nomeEmpresa"
                                                placeholder="Nome da empresa"
                                                type="text"
                                                defaultValue={getContrato.nomeEmpresa}
                                                onChange={(e) => {
                                                    setContrato((body) => {
                                                        return {
                                                            ...body,
                                                            nomeEmpresa: RemoverCaracteres(e.target.value)
                                                        }
                                                    })
                                                }}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="responsavelEmpresa">
                                                Responsável:
                                            </Label>

                                            <Input
                                                id="responsavelEmpresa"
                                                name="responsavelEmpresa"
                                                placeholder="Nome do Responsável"
                                                type="text"
                                                defaultValue={getContrato.responsavelEmpresa}
                                                onChange={(e) => {
                                                    setContrato((body) => {
                                                        return {
                                                            ...body,
                                                            responsavelEmpresa: RemoverCaracteres(e.target.value)
                                                        }
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="documentoEmpresa">
                                                CPF / CNPJ:
                                            </Label>

                                            <Input
                                                id="documentoEmpresa"
                                                name="documentoEmpresa"
                                                type="text"
                                                defaultValue={getContrato.documentoEmpresa}
                                                onChange={(e) => {
                                                    setContrato((body) => {
                                                        return {
                                                            ...body,
                                                            documentoEmpresa: RemoverCaracteres(e.target.value)
                                                        }
                                                    })
                                                }}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="WhatsAppEmpresa">
                                                WhatsApp:
                                            </Label>
                                            <Input
                                                id="WhatsAppEmpresa"
                                                name="WhatsAppEmpresa"
                                                type="text"
                                                defaultValue={getContrato.whatsApp}
                                                onChange={(e) => {
                                                    setContrato((body) => {
                                                        return {
                                                            ...body,
                                                            whatsApp: RemoverCaracteres(e.target.value)
                                                        }
                                                    })
                                                }}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col >
                                        <FormGroup>
                                            <Label for="enderecoEmpresa">
                                                Endereço:
                                            </Label>
                                            <Input
                                                id="enderecoEmpresa"
                                                name="enderecoEmpresa"
                                                type="text"
                                                defaultValue={getContrato.enderecoEmpresa}
                                                onChange={(e) => {
                                                    setContrato((body) => {
                                                        return {
                                                            ...body,
                                                            enderecoEmpresa: RemoverCaracteres(e.target.value)
                                                        }
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="cidadeEmpresa">
                                                Cidade:
                                            </Label>
                                            <Input
                                                id="cidadeEmpresa"
                                                name="cidadeEmpresa"
                                                defaultValue={getContrato.cidadeEmpresa}
                                                onChange={(e) => {
                                                    setContrato((body) => {
                                                        return {
                                                            ...body,
                                                            cidadeEmpresa: RemoverCaracteres(e.target.value)
                                                        }
                                                    })
                                                }}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="estadoEmpresa">
                                                Estado:
                                            </Label>
                                            <Input
                                                id="estadoEmpresa"
                                                name="estadoEmpresa"
                                                defaultValue={getContrato.estadoEmpresa}
                                                onChange={(e) => {
                                                    setContrato((body) => {
                                                        return {
                                                            ...body,
                                                            estadoEmpresa: RemoverCaracteres(e.target.value)
                                                        }
                                                    })
                                                }}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="cepEmpresa">
                                                CEP:
                                            </Label>
                                            <Input
                                                id="cepEmpresa"
                                                name="cepEmpresa"
                                                defaultValue={getContrato.cepEmpresa}
                                                onChange={(e) => {
                                                    setContrato((body) => {
                                                        return {
                                                            ...body,
                                                            cepEmpresa: RemoverCaracteres(e.target.value)
                                                        }
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup check>

                                    <Input
                                        id="empresaAtiva"
                                        name="empresaAtiva"
                                        type="checkbox"
                                        defaultChecked={getContrato.empresaAtiva}
                                        onChange={(e) => {
                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    empresaAtiva: e.target.checked
                                                }
                                            })
                                        }}
                                    />
                                    <Label
                                        check
                                        for="empresaAtiva"
                                    >
                                        Empresa ativa
                                    </Label>
                                </FormGroup>

                            </Form>
                        </div>

                        <div className="box-footer">
                            <Card style={{ border: 'none' }}>
                                <CardFooter>

                                    {!compareID() &&
                                        <Button color="danger" onClick={() => {
                                            //editarDadosAnunciante('DELETE', 'editar-cliente', 'Cliente deletado')

                                            //metodo,rota, msg, body, setfetchErro, id

                                            setVisibleCompAlert(true)
                                            setMsgAlert(() => {
                                                return {
                                                    tipo: 'deletar',
                                                    msg: 'Você tem certeza de que deseja deletar esse cliente?'
                                                }
                                            })

                                        }}>
                                            Deletar
                                        </Button>}

                                    <Button color="primary" onClick={() => {
                                        //editarDadosAnunciante('PATCH', 'editar-cliente', 'Cliente editado')
                                        //metodo,rota, msg, body, setfetchErro, id

                                        setMsgAlert(() => {
                                            return {
                                                tipo: 'editar',
                                                msg: 'Você tem certeza de que deseja editar esse cliente?'
                                            }
                                        })

                                        setVisibleCompAlert(true)

                                    }}>
                                        Editar
                                    </Button>

                                </CardFooter>
                            </Card>
                        </div>
                        {fetchErro.btn && verificarFetch()}
                    </AccordionBody>
                </AccordionItem>

            </Accordion>

            <div id="histotico-cliente">

                <div className="titulo-um">Histórico</div>
                <div className="topo-cliente-historico">
                    <div className="valores-historico ">Recebido: {dadosFetch && PrecoBrl(gerarValores().recebidos)}</div>
                    <div className="valores-historico">A receber:  {dadosFetch && PrecoBrl(gerarValores().aReceber)}</div>
                </div>

            </div>

            <Accordion open={openHist} toggle={toggleHist}>
                {gerarHistorico()}
            </Accordion>



            {visibleCompAlert && CompConfimacao(visibleCompAlert, setVisibleCompAlert)}


        </div>
    )
}


