


//Retirar caracteres especial 
export function RemoverCaracteres(string) {     
    
    let getStrint = string.replace(/[{}\\$`()[\]<>]/g, '')
         
    return getStrint;
}
