import './cadastroDeAnuncio.css'

import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { Link } from 'react-router-dom';
import {
    Button, Input, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, Progress, Alert,
} from 'reactstrap';



//com alert cadastr ode produtos
import CompALert from '../../components/CompAlert/CompAlert';



//props alerta info cadastro
import SuccessEnvioDados from '../../util/SuccessEnvioDados'

//importar função fetch
import { ConectURLPost, UrlServer } from '../../util/conect'

import moment from 'moment/moment';


//formatar moeda brl
import { PrecoBrl } from '../../util/precoBrl'

//função HeadersGet
import { HeadersGet } from '../../util/headers'

//Retirar caracteres especial 
import { RemoverCaracteres } from '../../util/tools'

//context todos os dados da api
import { UserContext } from '../../context/UserContext';

const dataSistema = moment().format("YYYY-MM-DD")
const animatedComponents = makeAnimated();



export default function CadastrodeAnuncio({ dados, setModal, modal }) {



    const [rSelected, setRSelected] = useState('MENSAL');

    //com alert cadastar  alerta
    const [visibleCompAlert, setVisibleCompAlert] = useState({ open: false, msg: '' });


    //aguardar tempo
    const [aguardeTempo, setaguardeTempo] = useState(false);

    //carregando dados recebido do fetch
    const [loadResponse, setloadResponse] = useState(false)
    const [sucessoFetch, setsucessoFetch] = useState(false)
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);


    //todos os dados do Form
    const [getContrato, setContrato] = useState(
        { ...dados }
    );

    //gerar intervalo de data do contrato
    const [FimContrato, setFimContrato] = useState('');

    //gerar intervalo de data  devinculacao
    const [fim_Vinculacao, set_FimVinculacao] = useState('');

    //carregando dados recebido do fetch
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })

    //alert cadastre um terminal primeiro
    const [notTerminal, setNotTerminal] = useState(false)

    //get terminal servidor
    const [optionsTerminal, setTerminais] = useState([]);

    //get terminal servidor
    const [optionsPlanos, setPlanos] = useState({});


    //verificar preço do plano
    const [novoAnuncio, setNovoAnuncio] = useState();



    //gerar intervalo de datas  
    function gerarIntervaloDedatas(dataRecebica, tempoContrato) {

        let tipo = 'months'
        let getTipoPagamento = novoAnuncio?.formPagemnto.split('-')[0] || 'months'
        let calcularTempo = 1


        switch (getTipoPagamento) {

            case 'DIARIO':

                tipo = 'days'
                calcularTempo = tempoContrato

                break;
            case 'MENSAL':

                tipo = 'months'
                calcularTempo = tempoContrato

                break;
            case 'TRIMESTRAL':

                tipo = 'months'
                calcularTempo = tempoContrato * 3

                break;
            case 'ANUAL':

                tipo = 'years'
                calcularTempo = tempoContrato

                break;
            default:
                break;
        }


        return moment(dataRecebica || moment())
            .add(Number(calcularTempo) || 0, tipo)
            .format("DD/MM/YYYY");

    }

    //gerar datas das  faturas
    function gerarDatasFatura() {

        let quantidadefaturas = []


        function calcularDatas(tempo, tipo) {

            for (let x = 0; x < tempo; x++) {

                switch (tipo) {

                    case 'DIARIO':
                        quantidadefaturas.push(
                            {
                                data: gerarIntervaloDedatas(getContrato.inicioVinculacao, x),
                                pago: false
                            }
                        )
                        break;
                    case 'MENSAL':
                        quantidadefaturas.push(
                            {
                                data: gerarIntervaloDedatas(getContrato.inicioVinculacao, x),
                                pago: false
                            }
                        )

                        break;
                    case 'TRIMESTRAL':

                        if (x === 1) {
                            quantidadefaturas.push(
                                {
                                    data: gerarIntervaloDedatas(getContrato.inicioVinculacao, 0),
                                    pago: false
                                }
                            )
                        }

                        break;
                    case 'ANUAL':


                        if (x === 1) {
                            quantidadefaturas.push(
                                {
                                    data: gerarIntervaloDedatas(getContrato.inicioVinculacao, 0),
                                    pago: false
                                }
                            )
                        }

                        break;
                    default:
                        break;
                }

            }



        }


        switch (optionsPlanos.valorPlano[0].formPagemnto) {

            case 'DIARIO':
                calcularDatas(getContrato.contratacao, 'DIARIO')
                break;
            case 'MENSAL':
                calcularDatas(getContrato.contratacao, 'MENSAL')
                break;
            case 'TRIMESTRAL':
                calcularDatas(getContrato.contratacao * 3, 'TRIMESTRAL')
                break;
            case 'ANUAL':
                calcularDatas(getContrato.contratacao * 12, 'ANUAL')
                break;
            default:
                break;
        }




        return quantidadefaturas
    }

    useEffect(() => {

        //gerar intervalo de data de contrato
        setFimContrato(gerarIntervaloDedatas(getContrato.inicioContrato, getContrato.contratacao))

        //gerar intervalo de data de vinculação     
        set_FimVinculacao(gerarIntervaloDedatas(getContrato.inicioVinculacao, getContrato.contratacao))



    }, [getContrato])

    useEffect(() => {




        fetch(`${UrlServer()}/buscar-terminais/${dados._id}`, HeadersGet())
            .then(response => response.json())
            .then(data => {


                setNotTerminal(data?.response.length > 0 ? true : false)

                let terminais =
                    data.response.map((nome) => {
                        return (
                            {
                                value: `${nome.nomeEmpresa}-${nome._id}`,
                                label: nome.nomeEmpresa
                            })

                    })

                setTerminais(terminais)



            })
            .catch((err) => {

                setfetchErro((fetchErro) => {
                    return {
                        ...fetchErro,
                        tipo: 'error',
                        msg: 'Erro ao inserir dados, tente novamente mais tarde.'
                    }
                })
            })




        fetch(`${UrlServer()}/buscar-planos`, HeadersGet())
            .then(response => response.json())
            .then(data => {


                /**************************** */
                const getObj = {};
                data.response.forEach((obj) => {
                    let { nomePlano } = obj

                    if (!getObj[nomePlano]) {
                        getObj[nomePlano] = []

                    }
                    getObj[nomePlano].push(obj)
                });

                const nomePlano = Object.keys(getObj).map((nome) => {
                    return (
                        {
                            value: `${nome}`,
                            label: nome
                        }
                    )
                })


                let infoPlano = data.response.map((nome) => {
                    return (
                        {
                            value: `${nome.infoPlano}-${nome._id}`,
                            label: nome.infoPlano
                        })
                })

                setPlanos({
                    todos: data.response,
                    infoPlano,
                    nomePlano
                })

            })
            .catch((err) => {

                setfetchErro((fetchErro) => {
                    return {
                        ...fetchErro,
                        tipo: 'error',
                        msg: 'Erro ao inserir dados, tente novamente mais tarde.'
                    }
                })
            })
    }, [])

    //quantidade: em dia || dias / mês || meses / ano || anos
    function quantidadeNome(diaMesAno) {


        const quabtidadeTempo = novoAnuncio?.contratacao || 0;
        const tipoDedata = diaMesAno?.toUpperCase() || 'MENSAL';

        let dadosRetorno = ''


        switch (tipoDedata) {

            case 'DIARIO':

                dadosRetorno = quabtidadeTempo > 1 ? 'em dias' : 'em dia'

                break;
            case 'MENSAL':

                dadosRetorno = quabtidadeTempo > 1 ? 'em meses' : 'em mês'

                break;
            case 'TRIMESTRAL':

                dadosRetorno = quabtidadeTempo > 1 ? 'em meses' : 'em mês'

                break;
            case 'ANUAL':

                dadosRetorno = quabtidadeTempo > 1 ? 'em anos' : 'em ano'

                break;
            default:
                break;
        }

        return dadosRetorno
    }

    console.log(notTerminal)
    if (!notTerminal) {

        return (
            <div id='alert-fetch-sucess' className='sucessoFetch'>
                <Alert color="danger">
                    Necessário cadastrar um terminal primeiro.
                    <br></br>
                    <Link to="/cadastrar-terminal"> Clique aqui para cadastrar.</Link>

                </Alert>
            </div>
        )
    }

    return (
        <>
            {
                // aguardeTempo && <Navigate to="/listar-anuncios" replace={true} />

            }

            {aguardeTempo && window.location.reload()}
            <div id='cadastro-anuncio'>

                <Form >

                    <div className="box-shadow">

                        <Row>


                            <Col md={6}>
                                <FormGroup>
                                    <Label for="plano">
                                        Plano:
                                    </Label>
                                    <Select
                                        id='plano'
                                        components={animatedComponents}
                                        placeholder={''}
                                        noOptionsMessage={() => 'Plano não cadastrado'}
                                        options={optionsPlanos.nomePlano}

                                        onChange={(e) => {

                                            let filterPlanos = optionsPlanos.todos.filter(value => e.value.toUpperCase() === value.nomePlano.toUpperCase())

                                            let mapPlanos = filterPlanos.map(val => {
                                                return (
                                                    {
                                                        value: `${val.formPagemnto}-${val._id}`,
                                                        label: val.formPagemnto
                                                    }
                                                )
                                            })

                                            setPlanos((options) => {
                                                return (
                                                    {
                                                        ...options,
                                                        formPagemnto: mapPlanos,
                                                        valorPlano: '',

                                                    }
                                                )
                                            })

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    plano: e.value
                                                }
                                            })

                                        }

                                        }
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label for="formPagemnto">
                                        Pagamento:
                                    </Label>
                                    <Select
                                        id='formPagemnto'
                                        options={optionsPlanos.formPagemnto}
                                        noOptionsMessage={() => ''}
                                        placeholder={''}
                                        isSearchable={false}


                                        onChange={(e) => {


                                            let filterPlanos = optionsPlanos.todos.filter(value => e.value.split('-')[1] === value._id)

                                            setPlanos((options) => {
                                                return (
                                                    {
                                                        ...options,
                                                        valorPlano: filterPlanos
                                                    }
                                                )
                                            })

                                            setRSelected(e.value.split('-')[0].toLowerCase())


                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    formPagemnto: e.value
                                                }
                                            })

                                        }}

                                    />

                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>

                                    <Label for="valorPlano">
                                        Valor:
                                    </Label>


                                    <Input
                                        id="valorPlano"
                                        name="valorPlano"
                                        type="text"
                                        defaultValue={optionsPlanos.valorPlano ? PrecoBrl(optionsPlanos.valorPlano[0].valorPlano) : ''}

                                        disabled

                                    />





                                </FormGroup>
                            </Col>


                        </Row>

                        <FormGroup>





                        </FormGroup>

                    </div>

                    <div className="box-shadow">

                        <Row>



                            <Col md={3}>
                                <FormGroup>
                                    <Label for="contratacao">
                                        Quantidade


                                        {
                                            ` ${quantidadeNome(rSelected)}`

                                        }

                                    </Label>
                                    <Input
                                        placeholder='3'
                                        id="contratacao"
                                        name="contratacao"
                                        type="number"
                                        defaultValue={getContrato.contratacao}
                                        onChange={(e) => {

                                            let arrayContratos = []
                                            for (let x = 0; x < Number(RemoverCaracteres(e.target.value)); x++) {
                                                arrayContratos.push({ chave: x, pago: false })
                                            }

                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    contratacao: RemoverCaracteres(e.target.value),
                                                    tempoDeContrato: arrayContratos
                                                }
                                            })

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    contratacao: e.target.value
                                                }
                                            })

                                        }}
                                    />
                                </FormGroup>
                            </Col>





                            <Col md={3}>
                                <FormGroup>
                                    <Label for="inicioContrato">
                                        Início do contrato:
                                    </Label>
                                    <Input
                                    disabled={novoAnuncio?.formPagemnto &&  novoAnuncio?.plano ? false : true}
                                        id="inicioContrato"
                                        name="inicioContrato"
                                        placeholder="Início do contrato"
                                        type="date"
                                        defaultValue={dataSistema}
                                        onChange={(e) => {


                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    inicioContrato: RemoverCaracteres(e.target.value)
                                                }
                                            })

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    inicioContrato: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>

                                    <Label for="fimContrato">
                                        Fim do contrato:
                                    </Label>


                                    <Input
                                    disabled={novoAnuncio?.formPagemnto &&  novoAnuncio?.plano ? false : true}
                                        id="fimContrato"
                                        name="fimContrato"
                                        placeholder="Fim do contrato"
                                        type="text"
                                        value={FimContrato || ''}
                                        onChange={(e) => {

                                        }}
                                    />






                                </FormGroup>
                            </Col>


                        </Row>


                    </div>

                    <div className="box-shadow">

                        <Row>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="terminal">
                                        Terminal:
                                    </Label>
                                    {optionsTerminal && <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={optionsTerminal}
                                        placeholder='Digite o terminal'
                                        noOptionsMessage={() => null}

                                        onChange={(e) => {

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    terminal: e.value,
                                                    terminalOption: e
                                                }
                                            })
                                        }}
                                    />
                                    }
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label for="inicioVinculacao">
                                        Início da veiculação:
                                    </Label>
                                    
                                    <Input
                                        disabled={novoAnuncio?.formPagemnto && novoAnuncio?.plano ? false : true}
                                        id="inicioVinculacao"
                                        name="inicioVinculacao"
                                        placeholder="Início da veiculação"
                                        type="date"
                                        defaultValue={dataSistema}
                                        onChange={(e) => {
                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    inicioVinculacao: e.target.value
                                                }

                                            })

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    inicioVinculacao: e.target.value
                                                }
                                            })


                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label for="fimVinculacao">
                                        Fim da veiculação:
                                    </Label>
                                    <Input
                                    disabled={novoAnuncio?.formPagemnto &&  novoAnuncio?.plano ? false : true}
                                        id="fimVinculacao"
                                        name="fimVinculacao"
                                        placeholder="Fim da veiculação"
                                        type="text"
                                        value={fim_Vinculacao || ''}
                                        onChange={(e) => {

                                        }}



                                    />

                                </FormGroup>
                            </Col>

                        </Row>

                    </div>

                    <div className="box-footer">
                        <Card style={{ border: 'none' }}>
                            <CardFooter>

                                <Button color="danger" onClick={() => {
                                    setModal(() => !modal)
                                }}>
                                    Cancelar
                                </Button>

                                <Button color="success" onClick={() => {


                                    if (!novoAnuncio?.plano) {
                                        return setVisibleCompAlert(() => {
                                            return {
                                                open: true,
                                                msg: 'Selecione um plano'
                                            }
                                        })

                                    }

                                    if (!novoAnuncio?.formPagemnto) {

                                        return setVisibleCompAlert(() => {
                                            return {
                                                open: true,
                                                msg: 'Selecione uma forma de pagamento'
                                            }
                                        })

                                    }

                                    if (!novoAnuncio?.contratacao) {

                                        return setVisibleCompAlert(() => {
                                            return {
                                                open: true,
                                                msg: 'Tempo de contrato obrigátorio'
                                            }
                                        })

                                    }

                                    if (!novoAnuncio?.terminalOption) {

                                        return setVisibleCompAlert(() => {
                                            return {
                                                open: true,
                                                msg: 'Selecione um terminal'
                                            }
                                        })

                                    }

                                    const terminais_selecionados = novoAnuncio?.terminalOption.map((value) => {
                                        return value.value
                                    })


                                    function gerarValorfatura(contratoTempo, getVal) {
                                        const getTempoDeContrato = contratoTempo?.contratacao ? contratoTempo.contratacao : 0
                                        const getTipoContratacao = contratoTempo?.formPagemnto ? contratoTempo.formPagemnto.split('-')[0] : ''

                                        const valorRecebiDoPlano = getVal.valorPlano ? Number(optionsPlanos.valorPlano[0].valorPlano) : 0

                                        let valorCalculado = 0

                                        switch (getTipoContratacao) {

                                            case 'DIARIO':

                                                valorCalculado = valorRecebiDoPlano;

                                                break;
                                            case 'MENSAL':

                                                valorCalculado = valorRecebiDoPlano;

                                                break;
                                            case 'TRIMESTRAL':

                                                valorCalculado = valorRecebiDoPlano * getTempoDeContrato;

                                                break;
                                            case 'ANUAL':

                                                valorCalculado = valorRecebiDoPlano * getTempoDeContrato;

                                                break;
                                            default:
                                                break;
                                        }

                                        return valorCalculado

                                    }

                                    const dadosParaEnvio =
                                    {
                                        ...novoAnuncio,
                                        valor: gerarValorfatura(novoAnuncio, optionsPlanos),
                                        FimContrato,
                                        inicioContrato: novoAnuncio.inicioContrato || dataSistema,
                                        inicioVinculacao: novoAnuncio.inicioVinculacao || dataSistema,
                                        fim_Vinculacao,
                                        id_cliente: dados._id,
                                        terminalOption: terminais_selecionados,
                                        nomeEmpresa: dados.nomeEmpresa,
                                        status: 'ativo',
                                        fatura: gerarDatasFatura(),
                                        whatsapp: dados.whatsApp
                                    }


                                    //return console.log(dadosParaEnvio)
                                    //rota,getContrato,setloadResponse,setsucessoFetch,setaguardeTempo    


                                    ConectURLPost(
                                        'efetuar-venda',
                                        dadosParaEnvio,
                                        setloadResponse,
                                        setsucessoFetch,
                                        setaguardeTempo
                                    )


                                }}>
                                    Cadastrar
                                </Button>



                            </CardFooter>
                        </Card>
                    </div>

                </Form>
                {loadResponse && <Progress
                    animated
                    value={100}
                />}
            </div>
            <SuccessEnvioDados
                sucessoFetch={sucessoFetch} //
                menssagem={'Novo anúncio cadastrado'}
                visible={visible}
                onDismiss={onDismiss}
            />

            {
                visibleCompAlert.open &&
                <CompALert
                    visibleCompAlert={visibleCompAlert}
                    setVisibleCompAlert={setVisibleCompAlert}
                />
            }
        </>
    )
}


