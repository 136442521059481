import './videoBg.css'

import { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

export default function VideoBg({ dadosEmpresa }) {

    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    
   
    // Função para lidar com o carregamento do vídeo
    const handleVideoLoaded = () => {
        setIsVideoLoaded(true);

    };





    return (
        <>
            <div id="vid">
                <video onCanPlayThrough={handleVideoLoaded} autoPlay muted loop type="video/mp4" src={require(`../../../videos/background/bgVid_${dadosEmpresa?.videoBg}.mp4`)}>
                </video>
            </div>

            {!isVideoLoaded &&
                (
                    <div className="load">
                        <CircularProgress />
                    </div>
                )
            }

        </>


    )
}