
import { Alert } from 'reactstrap';

//toggle={onDismiss} botão de fechar

export default function SuccessEnvioDados({ sucessoFetch, menssagem, visible, onDismiss,danger}) {

   
    
    return (
        <>
            {sucessoFetch &&
                <div id='alert-fetch-sucess' className='sucessoFetch'>
                    <Alert color={danger ? 'danger' : "info"} isOpen={visible}  >
                        {menssagem}
                    </Alert>
                </div>
            }
        </>
    )
}