
import './error.css'
import imgError from '../../img/error.jpg'

export default function Error(){
    return(
       <div id='imgError'>
        <img src={imgError}></img>
       </div>
    )
}