import './ContainerBanner.css'
import VideoBg from './videoBg'
import TextoDigitando from './textoDigitando'
import { useState, useEffect  } from 'react';


export default function ContainerBanner({ dadosEmpresa, videobg }) {

    const [atTop, setAtTop] = useState(true);   
    

    useEffect(() => {
        const handleScroll = () => {
            // Define como verdadeiro se o scroll estiver no topo
            setAtTop(window.scrollY <= 600);
            
        };

        // Adiciona o event listener ao scroll
        window.addEventListener('scroll', handleScroll);

        // Limpeza: remove o event listener quando o componente é desmontado
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    
    

    function tituloTExto() {
        return (
            <div className="tituloProposta">
                <h1>{dadosEmpresa?.tituloPrimario}</h1>
                <h2>{dadosEmpresa?.tituloSecundario}</h2>
            </div>
        )
    }

    
    if (!videobg) {
        return tituloTExto()
    } else {
        return (

            <div id="containerBanner">
                <div className="videobg">
                    <VideoBg dadosEmpresa={dadosEmpresa}/>
                </div>
                <div className="tituloPropostaVid">

                   {atTop && <TextoDigitando primeiroTexto={dadosEmpresa?.tituloPrimario} segundoTexto={dadosEmpresa?.tituloSecundario} />}


                </div>
            </div>

        )
    }


}