import Cookies from "js-cookie"


export function HeadersGet() {
    const nomeDb = JSON.parse(Cookies.get('stk-log')).dbNome
    let findToken = JSON.parse(Cookies.get('stk-log')).token

    const cabecalho = new Headers();

    cabecalho.append('nomeDb', nomeDb);
    cabecalho.append('authorization', `Bearer ${findToken}`);


    const opcoes = {
        headers: cabecalho
    };

    return opcoes
}


export function HeadersPost() {



    const cabecalho = new Headers();
    let findToken

    if (Cookies.get('stk-log')) {
        let nomeDb = JSON.parse(Cookies.get('stk-log')).dbNome
        findToken = JSON.parse(Cookies.get('stk-log')).token

        cabecalho.append('nomeDb', nomeDb);

    }


    cabecalho.append('authorization', `Bearer ${findToken}`);
    cabecalho.append('Accept', 'application/json');
    cabecalho.append('Content-Type', 'application/json');



    return cabecalho
}