


import './cardCaixa.css'

import { CardHeader, CardBody, Card, CardTitle, CardFooter } from 'reactstrap';

import { PrecoBrl } from '../../../util/precoBrl';



export default function CardFiltroMes({
    quantAnuncioMes,
    totalAreceber,
    totalMes,
    totalRecebido,
    mes,
    faturamentoAno
}) {


    //faturamento anual
    if (faturamentoAno) {

        return (
            <>
                <Card


                >
                    <CardHeader>

                        {`Faturamento do ano ${faturamentoAno.dataAno}`}

                    </CardHeader>
                    <CardBody>
                        <CardTitle tag="h5">

                            {
                                `${faturamentoAno.quantidadeAnunciosAno} anúncios veiculados`
                            }

                        </CardTitle>
                        <div>
                            <div style={
                                {
                                    fontSize: '1.1rem',
                                    color: 'green',
                                    fontWeight: '600'
                                }
                            }>
                                Recebido:


                                {
                                    ` ${PrecoBrl(faturamentoAno.totalRecebidosAno)}`
                                }

                            </div>
                            <div style={{
                                color: 'red'
                            }}>
                                
                                     A receber:
                                     {
                                         ` ${PrecoBrl(faturamentoAno.totalAreceberAno)}`
                                     }
                                
                            </div>
                        </div>


                    </CardBody>
                    <CardFooter>
                        Total:
                        {
                            ` ${PrecoBrl(faturamentoAno.totalAno)}`
                        }
                    </CardFooter>
                </Card>
            </>
        )
    }


    return (
        <>
            <Card


            >
                <CardHeader>

                    {`Faturamento do mês ${mes}`}

                </CardHeader>
                <CardBody>
                    <CardTitle tag="h5">
                        {

                            `${quantAnuncioMes > 0 ? `${quantAnuncioMes} novos anúncios veiculados` : 'Nenhum novo anúncio'}`

                        }
                    </CardTitle>
                    <div>
                        <div style={
                            {
                                fontSize: '1.1rem',
                                color: 'green',
                                fontWeight: '600'
                            }
                        }>
                            Recebido:


                            {
                                ` ${PrecoBrl(totalRecebido)}`
                            }

                        </div>
                        <div style={{
                            color: 'red'
                        }}>

                            A receber:
                            {
                                ` ${PrecoBrl(totalAreceber)}`
                            }
                        </div>
                    </div>


                </CardBody>
                <CardFooter>
                    Total:
                    {
                        ` ${PrecoBrl(totalMes)}`
                    }
                </CardFooter>
            </Card>
        </>
    )
}