
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//icones Card
import ShoppingBasketSharp from '@mui/icons-material/ShoppingBasketSharp';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';
import AddBusinessSharp from '@mui/icons-material/AddBusinessSharp';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import Tv from '@mui/icons-material/Tv';
import SmartDisplayRounded from '@mui/icons-material/SmartDisplayRounded';


export default function ModeloProposta({ dadosEmpresa }) {


    const icons = {
        Icon_01: ShoppingBasketSharp,
        Icon_02: ShoppingCart,
        Icon_03: ShoppingBasket,
        Icon_04: AddBusinessSharp,
        Icon_05: MonetizationOn,
        Icon_06: Tv,
        Icon_07: SmartDisplayRounded,
    };

    const CompeneteIcones = icons[dadosEmpresa.iconeCard]

    return (
        <>
            <div className="box-proposta">

                <div className="iconeProposta centerText">
                   
                    <CompeneteIcones style={{ color: dadosEmpresa?.iconeCor, fontSize: `${dadosEmpresa?.iconeTamanho}rem` }} />
                </div>

                <div className="NomePlano centerText"></div>

                <div className='precoPlano centerText' style={{ color: dadosEmpresa?.corSelecionada }}>
                    R$ 00,00
                    <span className="tipoPagamento">/mês</span>
                </div>


                <div className="descricaoPlano">


                    <div className='infoPlano'>
                        <CheckCircleIcon style={{ color: dadosEmpresa?.corSelecionada }} />
                        {`2 Estabelecimentos`}
                    </div>


                    <div className='infoPlano'>
                        <CheckCircleIcon style={{ color: dadosEmpresa?.corSelecionada }} />
                        exemplo
                    </div>
                </div>



                <div className="saibaMAisPlano centerText" style={{ color: dadosEmpresa?.corSelecionada }}>
                    Saiba mais
                </div>

                <div className="centerText">
                    <div className="btnContratar"

                        style={{ background: dadosEmpresa?.corBtn }}>
                        Contratar
                    </div>
                </div>

            </div>
        </>
    )
}