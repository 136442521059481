import React, { useState, useEffect, useRef } from 'react';
import Contador from './contador';

const IndexContador = ({ alvo, count }) => {
  
  const [visivel, setVisivel] = useState(false);
  const contadorRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setVisivel(true);
      } else {
        setVisivel(false);
      }
    }, { threshold: 0.1 });

    if (contadorRef.current) {
      observer.observe(contadorRef.current);
    }

    return () => {
      if (contadorRef.current) {
        observer.unobserve(contadorRef.current);
      }
    };
  }, []);

  return (
    <div>

      <div ref={contadorRef}>       
        {visivel && <Contador alvo={alvo} count={count}/>}
      </div>

    </div>
  );
};




export default IndexContador;
