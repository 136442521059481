import './anuncios.css'
import React, { useEffect, useState, useContext } from 'react';

//função HeadersGet
import { HeadersGet } from '../../../util/headers'

import { Link } from 'react-router-dom';
import {
    Progress, Alert, Modal, ModalHeader, ModalBody, Form, Col, Label
    , Input, FormGroup, Button
} from 'reactstrap';

//Retirar caracteres especial 
import { RemoverCaracteres } from '../../../util/tools'

//dados do anuciante
import Cliente from './cliente';

//importar urlGlobal 
import { UrlServer } from '../../../util/conect';

//context todos os dados da api
import { UserContext } from '../../../context/UserContext';



//função para buscar e filtrar buscas 
import { SearchFilter, PrintCard } from '../../../util/searchFilter'

export default function ListarClientes() {


    const { dadosAPI, setDadosAPI } = useContext(UserContext)

    const [status, setStaus] = useState('todo');

    const [getDados, setDados] = useState()

    const [modal, setModal] = useState(false);



    const toggle = () => {

        setModal(!modal)
        //window.location.reload()
    }



    //Fetch
    const [fetchErro, setfetchErro] = useState({ res: true, tipo: 'carregando', msg: '' })
    const [dadosFetch, setdadosFetch] = useState(false)

    //buscar
    const [buscarCliente, setbuscarCliente] = useState('')

    const [expandir, setExpadir] = useState(false)

    function verTodos(bodyDados, tipoStatus) {

        let quantStatus = []
        bodyDados.map((val) => {

            if (val.status === tipoStatus || tipoStatus === 'todo') {
                quantStatus.push(tipoStatus)
            }

        })

        if (quantStatus.length > 1) {
            return (

                <div id='verTodos'>
                    <div className='ExpandirLista' onClick={() => setExpadir((e) => !e)}>
                        {expandir ? 'Reduzir lista' : 'Expandir lista'}
                    </div>
                </div>

            )
        }

    }



    useEffect(() => {


        if (!dadosAPI?.buscarVendaAnuncios) {


            fetch(`${UrlServer()}/buscar-venda`, HeadersGet())
                .then(response => response.json())
                .then(data => {

                    if (data.connected) {



                        setdadosFetch(data.response)

                        //colocar todos os dados da API no context
                        setDadosAPI((dadosAPI) => {

                            return {
                                ...dadosAPI,
                                buscarVendaAnuncios: data.response
                            }
                        })

                        setfetchErro((err) => {
                            return {
                                ...err,
                                res: false
                            }
                        })
                    } else {
                        setfetchErro((err) => {
                            return {
                                ...err,
                                tipo: 'error',
                                msg: data.response
                            }
                        })
                    }

                })
                .catch((error) => {
                    let errorString = new String(error)
                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: errorString
                        }
                    })

                })

        } else {
            setdadosFetch(dadosAPI.buscarVendaAnuncios)
            setfetchErro((err) => {
                return {
                    ...err,
                    res: false
                }
            })

        }

    }, [])



    if (fetchErro.res) {
        if (fetchErro.tipo === 'carregando') {
            return (
                <div className='stk-body'>
                    <div className="carregando-fetch" style={{ paddingBottom: '10px' }}>
                        <Progress
                            animated
                            value={100}
                            color="warning"
                        />
                    </div>
                </div>
            )
        } else if (fetchErro.tipo === 'error') {
            return (
                <div className='stk-body'>
                    <div className="box-shadow-error" style={{ paddingBottom: '10px' }}>
                        <Alert color="danger">
                            Não foi possivel se conectar com oservidor.
                            <br></br>
                            Tente novamente mais tarde.
                            <br></br>
                            <code style={{ fontSize: '.7rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>
                </div>
            )
        }
    }


    if (dadosFetch.length === 0) {
        return (
            <>
                <div className="stk-body">

                    <Alert color="danger" isOpen={true} >
                        Necessário cadastrar um anúncio primeiro.
                        <br></br>
                        <Link to="/novo-anuncio"> Clique aqui para cadastrar.</Link>
                    </Alert>

                </div>
            </>
        )
    }



    function retirarClasse(statusBtn, statusBody) {

        if (statusBtn === 'todo') {
            return true
        }

        return statusBtn === statusBody

       

    }

    return (
        <>

            <div id='listar-clientes' className='stk-body'>
                <div className="titulo-um">
                    Anúncios
                </div>

                <div className="box-shadow">
                    <div className="box-busca">
                        <Form>

                            <Col>
                                <FormGroup>
                                    <Label for="buscarCliente">
                                        Buscar cliente:
                                    </Label>
                                    <Input
                                        className='icone-input-buscar'
                                        id="buscarCliente"
                                        name="buscarCliente"
                                        type="search"
                                        placeholder='Buscar cliente'
                                        onChange={(e) => {

                                            setbuscarCliente(RemoverCaracteres(e.target.value))


                                        }}

                                    />
                                </FormGroup>
                            </Col>

                            <FormGroup>
                            </FormGroup>
                        </Form>
                    </div>

                </div>

                <div className="box-shadow-btn">
                    <div className='status-btn'>

                        <Button size='sm'
                            color="secondary"
                            onClick={() => setStaus('todo')}
                        >
                            Todos
                        </Button>

                        <Button size='sm'
                            color="secondary"
                            onClick={() => setStaus('ativo')}
                        >
                            Ativos
                        </Button>

                        <Button size='sm'
                            color="secondary"
                            onClick={() => setStaus('pausado')}
                        >
                            Pausado
                        </Button>
                        <Button size='sm'
                            color="secondary"
                            onClick={() => setStaus('finalizado')}
                        >
                            Finalizados
                        </Button>

                    </div>
                </div>

                <div className='titulo-um'>
                    {buscarCliente.length > 0 ? '' : `${status.toUpperCase()}S`}
                </div>

                <div className="box-shadoww">

                    <div className="box-empresa box-empresa-list">

                        {buscarCliente.length > 0 ? (
                            SearchFilter(dadosFetch, buscarCliente, buscarCliente, 'nomeEmpresa').map((body, ind) => {


                                return (

                                    <span id='lisr-primaria-card' key={ind}>
                                        {PrintCard(body, setDados, setModal, modal, `novo-anuncio?anuncio=${buscarCliente}`, 'anuncios', null, null, body.status)}

                                    </span>
                                )
                            })
                        ) : (


                            dadosFetch?.map((body, ind) => {

                                //mostar quantidade de produtos na listagem
                                let quantidade = expandir ? dadosFetch.length : 5

                                if (retirarClasse(status, body.status)) {
                                    if (ind < quantidade) {

                                        return (
                                            <span id={'lisr-primaria-card'} key={ind}>
                                                {
                                                    body.status?.toUpperCase() === status?.toUpperCase() || status === 'todo' ?
                                                        (PrintCard(body, setDados, setModal, modal, null, 'anuncios', null, status))
                                                        : null
                                                }

                                            </span>
                                        )

                                    }
                                }






                            })




                        )
                        }


                        {verTodos(dadosFetch, status)}
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>{getDados?.nomeEmpresa || ''}</ModalHeader>
                <ModalBody>
                    <Cliente dados={getDados} setModal={setModal} modal={modal} dadosBody={getDados} setDados={setDados} />
                </ModalBody>

            </Modal>
        </>
    )
}
