import './graficoBar.css'

import Chart from "react-apexcharts";



export default function GraficoBAr({ dadoBar, titulo }) {

  // let formatado  = dadoBar
  if (titulo === 'Vendas') {

    const dadosFormatados = dadoBar?.map(({ x, y }) => ({
      x,
      y: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(y),
    }));


    //formatado = dadosFormatados
  }
  const labelInfo = {
    enabled: true,
    formatter: (val, opts) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val),
    offsetX: 20,
    style: {
      colors: ['#333333'], 
      borderColor: ['']
    },

    


  }

  const labelInfo1 = {
    enabled: true,
    style: {
      colors: ['#333333'], 
    },
  }

  const options = {
    chart: {      
      width: "100%",

      animations: {
        initialAnimation: {
          enabled: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        
      }
    },
    series: [
      {
        name: titulo,
        data: dadoBar
      }
    ],
    dataLabels: titulo === 'Vendas' ? labelInfo : labelInfo1,
    fill: {
      colors: ['#a9a9a9']
    }

    
  };

  return (
    <>
      {dadoBar && <Chart
        options={options}
        series={options.series}
        type='bar'

      />}
    </>
  )
}