
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import './style/tema.css'

import { Outlet } from "react-router";
import React, { useContext, useState } from 'react';

import SubTopo from './components/topo/subTopo/subTopo'

import Topo from "./components/topo/topo";
import MenuPrincipal from './components/menu/menu'

import Private from './page/private/private'
import { UserContext } from './context/UserContext';


//React Query

function App() {

  const [openMenu, setOpenMenu] = useState(false)
  const { tema, setTema } = useContext(UserContext)

  return (
    
    
      <Private>
      <main id='container-stk' className={tema === 'padrao' ? `${tema}-color` : `${tema}-color`}>

        <MenuPrincipal openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <section className={`container-stk ${tema}-body`} >
          <Topo setOpenMenu={setOpenMenu} />

         <SubTopo />
         

        <div className={openMenu ? `outlet-hide` : 'outlet-show'}>
            < Outlet />
          </div>

        </section>

      </main>
      </Private>
    


  );
}

export default App;
