
import { useState } from 'react'
import './uploadImg.css'

import {
    Input, FormText, Button, Modal, ModalBody, ModalFooter
} from 'reactstrap'

export default function UploadImg({ idUpload, nameUpload, formTextUpload, chaveAPI, setProposta, Proposta, rotaUpload }) {

    const [uploadImg, setUploadImg] = useState(false)
    const [carregando, setCarregando] = useState(true)
    const [getImagem, setImagem] = useState()
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const getFile = (e) => {

        const files = e.target.files[0]
        const formData = new FormData()
        formData.append('image', files)

        setUploadImg(formData)

    }

    const uploadImagem = async () => {

        if (!uploadImg) return

        setCarregando((tipo) => !tipo)

        await fetch(`https://api.imgbb.com/1/upload?key=${chaveAPI}`, {
            method: 'POST',
            body: uploadImg,


        }).then(response => response.json())
            .then(data => {


                setCarregando((tipo) => !tipo)

                if (rotaUpload === 'marcasClientes') {
                    const copiaproposta = Proposta
                    copiaproposta.marcasClientes.push(data.data.url)
                    copiaproposta.marcasClientesDelete.push(data.data.delete_url)


                    setProposta((body) => {
                        return {
                            ...body,
                            marcasClientes: copiaproposta.marcasClientes,
                            marcasClientesDelete: copiaproposta.marcasClientesDelete
                        }
                    })
                } else if (rotaUpload === 'insercoesMes') {

                    setProposta((body) => {
                        return {
                            ...body,
                            insercoesMes: data.data.url,
                            insercoesMesDelete: data.data.delete_url
                        }
                    })
                }

            })

    }

    const deleteImagem = (ind) => {


        if (rotaUpload === 'marcasClientes') {

            window.open(Proposta.marcasClientesDelete[ind])
            const copiarProposta = Proposta

            copiarProposta.marcasClientesDelete.splice(ind, 1)
            copiarProposta.marcasClientes.splice(ind, 1)


            setProposta((body) => {
                return {
                    ...body,
                    marcasClientes: copiarProposta.marcasClientes,
                    marcasClientesDelete: copiarProposta.marcasClientesDelete,
                }
            })

        } else if (rotaUpload === 'insercoesMes' && Proposta?.insercoesMes?.length > 1) {

            
            window.open(Proposta.insercoesMesDelete)

            setProposta((body) => {
                return {
                    ...body,
                    insercoesMes: '0',
                    insercoesMesDelete: '0',
                }
            })
        }




    }


    const printBody = () => {

        if (rotaUpload === 'marcasClientes') {


            return Proposta?.marcasClientes?.map((val, index) => {
                return (

                    <div className="logoMarcas" key={val}>
                        <img src={val} alt="Imagem logo" onClick={() => {
                            toggle()
                            setImagem({ img: val, index: index })
                        }} />
                    </div>
                )
            })



        } else if (rotaUpload === 'insercoesMes') {

            
            return (

                <div className="logoMarcas">

                    {Proposta?.insercoesMes?.length > 1 ?
                        (<img src={Proposta?.insercoesMes} alt="Imagem logo" onClick={() => {
                            toggle()
                            setImagem({ img: Proposta?.insercoesMes, index: Proposta?.insercoesMes })
                        }} />)
                        : null}

                </div>
            )


        }
    }

    return (
        <>
            <Input
                id={idUpload}
                name={nameUpload}
                type="file"
                onChange={(e) => getFile(e)}
            />

            <FormText>
                {formTextUpload}
            </FormText>

            <p></p>

            <Button className='btnUpload' color='primary' onClick={uploadImagem} disabled={!carregando ? true : false}>
                {carregando ? 'Upload' : 'Carregando'}
            </Button>

            <div className="boxImagemMarcas">
                {printBody()}
            </div>

            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalBody>
                    <div className="logoMarcas">
                        <img src={getImagem?.img} alt="Imagem clientes" />
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button color='danger' onClick={() => {
                        deleteImagem(getImagem?.index)
                        toggle()
                    }}>Deletar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}