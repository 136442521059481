
import './IconesCard.css'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//icones Card
import ShoppingBasketSharp from '@mui/icons-material/ShoppingBasketSharp';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';
import AddBusinessSharp from '@mui/icons-material/AddBusinessSharp';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import Tv from '@mui/icons-material/Tv';
import SmartDisplayRounded from '@mui/icons-material/SmartDisplayRounded';



export default function IconesCard({ Proposta, setProposta }) {


    const icons = {
        Icon_01: ShoppingBasketSharp,
        Icon_02: ShoppingCart,
        Icon_03: ShoppingBasket,
        Icon_04: AddBusinessSharp,
        Icon_05: MonetizationOn,
        Icon_06: Tv,
        Icon_07: SmartDisplayRounded,
    };


    

    const iconsPrint = Object.keys(icons).map((value) => {
        
        const IconeComponete = icons[value]
        return (
            <div key={value} className="boxIcones" onClick={() => {
                setProposta((body) => {
                    return {
                        ...body,
                        iconeCard: value
                    }
                })
            }}>
                <IconeComponete  style={{ color: Proposta?.iconeCor, fontSize: `${Proposta?.iconeTamanho}rem` }}/>
                

                <div className={`iconeAtivo`} style={{ display: `${value == Proposta?.iconeCard ? '' : 'none'}` }}>
                    <CheckCircleIcon />
                </div>
            </div>

        )
    })


    return (
        <>
            <div id="ContainerIcones">
                {iconsPrint}
            </div>
        </>
    )
}