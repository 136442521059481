import '../../../style/pageDentroModal.css'
import './cadastroDeAnuncio.css'

import Select from 'react-select'
import React, { useState } from 'react';
import {
    Button, Input, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, Progress, Alert
} from 'reactstrap';


//importar função fetch
import { ConectURL } from '../../../util/conect'



export default function CadastrodeAnuncio({ dados }) {

   

    //confirmação alerta info
    const [visibleCompAlert, setVisibleCompAlert] = useState(false);
    const [msgAlert, setMsgAlert] = useState();



    //todos os dados do Form
    const [getContrato, setContrato] = useState(
        { ...dados }
    );

    //carregando dados recebido do fetch
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })

    //alerta fim do update alertToggle
    const [alertTg, setAlertTg] = useState(true);



    function alertToggle() {
        setAlertTg((to) => !to)
        setfetchErro((body) => {
            return {
                ...body,
                btn: false
            }
        })
    }


    //Retirar caracteres especial 
    function removerCaracteres(string) {
        return string.replace(/[{}$()[\]<>]/g, '')
    }



    function verificarFetch() {

        if (fetchErro.res) {

            if (fetchErro.tipo === 'carregando') {
                return (

                    <Progress
                        animated
                        value={100}
                    />

                )
            } else if (fetchErro.tipo === 'error') {

                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert color="danger">
                            <code style={{ fontSize: '.8rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>

                )
            } else if (fetchErro.tipo === 'sucesso') {

                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>

                        <Alert
                            isOpen={alertTg}
                            toggle={alertToggle}
                            color={fetchErro.metodoFetch === 'DELETE' ? 'danger' : 'success'}
                            onClick={() => {

                                window.location.reload()

                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                {fetchErro.msg}

                            </span>

                        </Alert>
                    </div>

                )
            }
        }
    }


    //vrificar confirmação
    function CompConfimacao(visibleCompAlert, setVisibleCompAlert) {
        return (
            <>
                <div id="comp-alert-confirm">
                    <Alert color="danger" isOpen={visibleCompAlert} style={{ maxWidth: '300px' }}>

                        {msgAlert.msg}

                        <div id='btn-alert-confirm'>



                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => {
                                    setVisibleCompAlert(false)


                                }}
                            >
                                Não
                            </Button>

                            <Button
                                color="success"
                                size="sm"
                                onClick={() => {

                                    setVisibleCompAlert(false)


                                    msgAlert.tipo === 'deletar' ?

                                        //metodo,rota, msg, body, setfetchErro, id
                                        // editarDadosAnunciante('DELETE', 'editar-plano', 'Plano deletado')
                                        ConectURL(
                                            'DELETE',
                                            'editar-plano',
                                            'Plano deletado',
                                            null,
                                            setfetchErro,
                                            getContrato._id
                                        )
                                        :

                                        ConectURL(
                                            'PATCH',
                                            'editar-plano',
                                            'Plano editado',
                                            getContrato,
                                            setfetchErro,
                                            getContrato._id
                                        )

                                }}
                            >
                                Sim
                            </Button>
                        </div>

                    </Alert>
                </div>
            </>
        )
    }

    return (


        <div id='plano-body' >



            <div className="box-shadow">
                <Form>
                    <Row>


                        <Col md={4}>
                            <FormGroup>
                                <Label for="plano">
                                    Nome do plano:
                                </Label>
                                <Input
                                    id='plano'
                                    type='text'
                                    defaultValue={dados.nomePlano}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                nomePlano: removerCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={3}>
                            <FormGroup>
                                <Label for="formPagemnto">
                                    Tipo de pagamento:
                                </Label>

                                <Select
                                    name="formPagemnto"
                                    id="formPagemnto"
                                    placeholder='Escolha'
                                    defaultValue={[{ value: dados.formPagemnto, label: dados.formPagemnto }]}
                                    options={[
                                        { value: 'DIARIO', label: 'Diário' },
                                        { value: 'MENSAL', label: 'Mensal' },
                                        { value: 'TRIMESTRAL', label: 'Trimestral' },
                                        { value: 'ANUAL', label: 'Anual' },

                                    ]}
                                    onChange={(e) => {


                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                formPagemnto: e.value
                                            }
                                        })
                                    }}



                                />

                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>

                                <Label for="valorPlano">
                                    Valor:
                                </Label>


                                <Input
                                    id="valorPlano"
                                    name="valorPlano"
                                    type="number"
                                    defaultValue={dados.valorPlano}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                valorPlano: removerCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />





                            </FormGroup>
                        </Col>

                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="quantTerminal">
                                        Quantidade de terminais:
                                    </Label>

                                    <Input
                                        id="quantTerminal"
                                        name="quantTerminal"
                                        type="number"
                                        defaultValue={dados.quantTerminal}
                                        onChange={(e) => {
                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    quantTerminal: e.target.value
                                                }
                                            })
                                        }}
                                    />

                                </FormGroup>
                            </Col>


                            <Col md={4}>
                                <FormGroup>
                                    <Label for="labelPlano">
                                        Rótulo caixa de preço (opcional):
                                    </Label>

                                    <Input
                                        id="labelPlano"
                                        name="labelPlano"
                                        type="text"
                                        defaultValue={dados?.labelPlano}
                                        onChange={(e) => {
                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    labelPlano: e.target.value
                                                }
                                            })
                                        }}
                                    />

                                </FormGroup>
                            </Col>
                        </Row>

                        <Col md={6}>
                            <FormGroup>

                                <Label for="infoPlano">
                                    Descrição do plano:
                                </Label>


                                <Input
                                    id="infoPlano"
                                    name="infoPlano"
                                    type="textarea"
                                    placeholder='02 pontos comerciais e 01 vídeio de 15s grátis.'
                                    defaultValue={dados.infoPlano}
                                    rows="5"
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                infoPlano: removerCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />

                            </FormGroup>
                        </Col>

                    </Row>
                    <FormGroup check>

                        <Input
                            id="planoAtivo"
                            name="planoAtivo"
                            type="checkbox"
                            defaultChecked={dados.planoAtivo}

                            onChange={(e) => {
                                setContrato((body) => {
                                    return {
                                        ...body,
                                        planoAtivo: e.target.checked
                                    }
                                })
                            }}
                        />
                        <Label
                            check
                            for="planoAtivo"
                        >
                            Plano ativo
                        </Label>
                    </FormGroup>

                </Form>
            </div>


            <div className="box-footer">
                <Card style={{ border: 'none' }}>
                    <CardFooter>

                        <Button color="danger" onClick={() => {


                            setVisibleCompAlert(true)
                            setMsgAlert(() => {
                                return {
                                    tipo: 'deletar',
                                    msg: 'Você tem certeza de que deseja deletar esse plano?'
                                }
                            })

                        }}>
                            Deletar
                        </Button>

                        <Button color="primary" onClick={() => {

                            //editarDadosAnunciante('PATCH', 'editar-plano', 'Plano editado')

                            setVisibleCompAlert(true)
                            setMsgAlert(() => {
                                return {
                                    tipo: 'editar',
                                    msg: 'Você tem certeza de que deseja editar esse plano?'
                                }
                            })


                        }}>
                            Salvar
                        </Button>
                    </CardFooter>
                </Card>
            </div>
            {fetchErro.btn && verificarFetch()}

            {visibleCompAlert && CompConfimacao(visibleCompAlert, setVisibleCompAlert)}
        </div>
    )
}


