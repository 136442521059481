

import './cardProposta.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';



import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ScrollOuvinte } from '../../util/scroll';


//icones Card
import ShoppingBasketSharp from '@mui/icons-material/ShoppingBasketSharp';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';
import AddBusinessSharp from '@mui/icons-material/AddBusinessSharp';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import Tv from '@mui/icons-material/Tv';
import SmartDisplayRounded from '@mui/icons-material/SmartDisplayRounded';

export default function Cardproposta({ body, dadosEmpresa }) {

    

    const icons = {
        Icon_01: ShoppingBasketSharp,
        Icon_02: ShoppingCart,
        Icon_03: ShoppingBasket,
        Icon_04: AddBusinessSharp,
        Icon_05: MonetizationOn,
        Icon_06: Tv,
        Icon_07: SmartDisplayRounded,
    };

    const CompeneteIcones = icons[dadosEmpresa.iconeCard]

    const [atTop, setAtTop] = useState(true); // Assume que começa no topo
    const [btnPlano, setBtnPlano] = useState(gerarOrdem().primeiroPlano)
    const [tipoBtn, setTipoBtn] = useState(gerarOrdem().primeiroPlano)
    const [modal, setModal] = useState(false);
    const [bodyInfo, setBodyInfo] = useState()



    const toggle = () => setModal(!modal);

    function NomeFormaPagamento(tipo) {

        const forma = tipo.split('-')[0].replace(/[^\p{L}]/gu, '').toUpperCase();

        switch (forma) {
            case 'DIARIO':

                return 'dia'

                break;
            case 'MENSAL':

                return 'mês'
                break;

            case 'TRIMESTRAL':

                return '3 meses'
                break;

            case 'ANUAL':

                return 'ano'
                break;

            default:
                break;
        }

    }

    function enviarZap(target) {

        const nomePlano = target.querySelector('.NomePlano').textContent
        const preco = target.querySelector('.precoPlano').textContent.split('/')[0]
        const text = `Gostaria de obter mais informações: \n${nomePlano} - ${btnPlano} \n${preco}`

        window.open('https://wa.me/55' + (body?.dadosEmpresa[0]?.whatsApp) + '?text=' + text)

    }

    function limitarTextoIndo(text) {

        if (text.length > 17) {
            return text.slice(0, 20) + '...'
        } else {
            return text
        }
    }

    function printBody() {

        return body.todosPlanos.map((val) => {

            if (val.formPagemnto === btnPlano) {

                if (val.planoAtivo) {
                    return (
                        <div key={val._id} className="box-proposta">

                            {val?.labelPlano && (<div className="economia-fx" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}>{val?.labelPlano}</div>)}

                            <div className="iconeProposta centerText">
                                
                                
                               { <CompeneteIcones style={{ color: dadosEmpresa?.iconeCor, fontSize: `${dadosEmpresa?.iconeTamanho}rem` }} />}
                           
                            </div>

                            <div className="NomePlano centerText">{val.nomePlano}</div>

                            <div className='precoPlano centerText' style={{ color: dadosEmpresa?.corSelecionada }}>
                                {Number(val.valorPlano).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}<span className="tipoPagamento">/{NomeFormaPagamento(val.formPagemnto)}</span>
                            </div>


                            <div className="descricaoPlano">
                                {
                                    val?.quantTerminal &&
                                    <div className='infoPlano'>
                                        <CheckCircleIcon style={{ color: dadosEmpresa?.corSelecionada }} />
                                        {`${val?.quantTerminal} Estabelecimentos`}
                                    </div>
                                }

                                <div className='infoPlano'>
                                    <CheckCircleIcon style={{ color: dadosEmpresa?.corSelecionada }} />
                                    {limitarTextoIndo(val.infoPlano)}
                                </div>
                            </div>



                            <div className="saibaMAisPlano centerText" style={{ color: dadosEmpresa?.corSelecionada }}
                                onClick={() => {

                                    setBodyInfo(() => {
                                        return {
                                            ...val,
                                            infomacoes: val.infoPlano.split("\n")
                                        }
                                    })
                                    toggle()
                                }}>
                                Saiba mais
                            </div>

                            <div className="centerText">
                                <div className="btnContratar"

                                    style={{ background: dadosEmpresa?.corBtn }}
                                    onClick={(e) => {
                                        enviarZap(e.target.parentNode.parentNode)
                                    }}>
                                    Contratar
                                </div>
                            </div>

                        </div>
                    )
                }
            }




        })

    }

    function gerarOrdem() {
        let arrPlanos = []
        body.todosPlanos.map((val) => {

            if (!arrPlanos.includes(val.formPagemnto)) {
                if (val.planoAtivo) {
                    arrPlanos.push(val.formPagemnto)
                }

            }
        })

        const ordem = arrPlanos.map((val) => {

            return {
                tipoData: val,
                ordem: val === 'DIARIO' ? 1 : val === "MENSAL" ? 2 : val === "TRIMESTRAL" ? 3 : val === "ANUAL" ? 4 : ''
            }

        })

        const colocarEmOrdem = ordem.sort((a, b) => a.ordem - b.ordem)

        return { ordemCres: colocarEmOrdem, primeiroPlano: colocarEmOrdem[2]?.ordem === 3 ? "TRIMESTRAL" : colocarEmOrdem[0].tipoData }

    }

    function getTypeFlat() {



        return gerarOrdem().ordemCres.map((value, index) => {


            return (
                <div key={index} >
                    <Button
                        className={`btnPlano ${value.tipoData ==  tipoBtn ? 'btnOpacity' : ''}`} 
                        style={
                            {
                                backgroundColor: dadosEmpresa?.corBtn,                              

                            }
                        }
                        value={value.tipoData}
                        key={Number(Date.now()) + index}
                        onClick={(e) => {
                            setBtnPlano(e.target.value)
                            //tipoBtn, setTipoBtn
                            
                            setTipoBtn( e.target.value)
                           
                        }}

                    >
                        {value.tipoData}
                    </Button>

                </div>

            )
        })
    }


    ScrollOuvinte(setAtTop, 200)

    const scrollbarStyle = `
    ::-webkit-scrollbar-thumb {
      background-color: ${dadosEmpresa?.corSelecionada};
    }
  `;

    return (
        <>
            <div id="btnPlanos">
                <div className="btnPlanoClass">
                    {getTypeFlat()}
                </div>
            </div>

            <style>{scrollbarStyle}</style>

            <div className="containerProposta" style={

                {
                    transition: ".5s",
                    marginTop: !atTop && dadosEmpresa?.habilitarBtn ? "350px" : "0"

                }}>
                <div id="boxContainerProposta">
                    {body && printBody()}
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>{bodyInfo?.nomePlano}</ModalHeader>
                <ModalBody>

                    {bodyInfo?.infomacoes.map((value, ind) => {
                        return (
                            <div className='descricaoPlanoModal' key={ind}>
                                <CheckCircleIcon style={{ color: dadosEmpresa?.corSelecionada }} />
                                {value}
                            </div>
                        )
                    })}
                </ModalBody>
            </Modal>
        </>
    )
}