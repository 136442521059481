
import '../../../style/pageForaDoModal.css'
import './cardPlano.css'
import './listarPlanos.css'


import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    Progress, Alert, Modal, ModalHeader, ModalBody, Form, Col, Label
    , Input, FormGroup
} from 'reactstrap';

//dados do anuciante
import PlanoCadastrado from './PlanoCadastrado';

//Retirar caracteres especial 
import { RemoverCaracteres } from '../../../util/tools'


//função para buscar e filtrar buscas 
import { SearchFilter, PrintCard } from '../../../util/searchFilter'


import { HeadersGet } from '../../../util/headers';

//context todos os dados da api
import { UserContext } from '../../../context/UserContext';
import { UrlServer } from '../../../util/conect';
//const { dadosAPI, setDadosAPI} = useContext(UserContext)

export default function Planos() {

    const { dadosAPI, setDadosAPI } = useContext(UserContext)

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [getDados, setDados] = useState()

    //Fetch
    const [fetchErro, setfetchErro] = useState({ res: true, tipo: 'carregando', msg: '' })
    const [dadosFetch, setdadosFetch] = useState(false)


    //buscar
    const [buscarCliente, setbuscarCliente] = useState('')


    useEffect(() => {

        if (!dadosAPI?.buscarClientePlanos) {

            fetch(`${UrlServer()}/buscar-planos`, HeadersGet())
                .then(response => response.json())
                .then(data => {

                    data.response.sort((a, b) => a.nomePlano
                        .localeCompare(b.nomePlano, 'pt', { sensitivity: 'base' }));

                    if (data.connected) {

                        setdadosFetch(data.response)

                        //colocar todos os dados da API no context
                        setDadosAPI((dadosAPI) => {

                            return {
                                ...dadosAPI,
                                buscarClientePlanos: data.response
                            }
                        })


                        setfetchErro((err) => {
                            return {
                                ...err,
                                res: false
                            }
                        })
                    } else {
                        setfetchErro((err) => {
                            return {
                                ...err,
                                tipo: 'error',
                                msg: data.response
                            }
                        })
                    }

                })
                .catch((error) => {

                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: "Erro interno"
                        }
                    })

                })

        } else {
            setdadosFetch(dadosAPI.buscarClientePlanos)
            setfetchErro((err) => {
                return {
                    ...err,
                    res: false
                }
            })

        }
    }, [])









    if (fetchErro.res) {
        if (fetchErro.tipo === 'carregando') {
            return (
                <div className='stk-body'>
                    <div className="carregando-fetch" style={{ paddingBottom: '10px' }}>
                        <Progress
                            animated
                            value={100}
                            color="warning"
                        />
                    </div>
                </div>
            )
        } else if (fetchErro.tipo === 'error') {
            return (
                <div className='stk-body'>
                    <div className="box-shadow-error" style={{ paddingBottom: '10px' }}>
                        <Alert color="danger">
                            Não foi possivel se conectar com oservidor.
                            <br></br>
                            Tente novamente mais tarde.
                            <br></br>
                            <code style={{ fontSize: '.7rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>
                </div>
            )
        }
    }

    if (dadosFetch.length === 0) {
        return (
            <>
                <div className="stk-body">

                    <Alert color="danger" isOpen={true} >
                        Necessário cadastrar um plano primeiro.
                        <br></br>
                        <Link to="/cadastrar-plano"> Clique aqui para cadastrar.</Link>
                    </Alert>

                </div>
            </>
        )
    }





    return (
        <>

            <div id='planos-body' className='stk-body'>
                <div className="titulo-um">
                    Planos cadastrados
                </div>

                <div className="box-shadow">
                    <div className="box-busca">
                        <Form>

                            <Col>
                                <FormGroup>
                                    <Label for="plano">
                                        Buscar plano:
                                    </Label>
                                    <Input
                                        className='icone-input-buscar'
                                        id="buscarCliente"
                                        name="buscarCliente"
                                        type="search"
                                        placeholder='Buscar plano'
                                        onChange={(e) => {
                                            setbuscarCliente(RemoverCaracteres(e.target.value))


                                        }}

                                    />
                                </FormGroup>
                            </Col>

                            <FormGroup>
                            </FormGroup>
                        </Form>
                    </div>

                </div>

                <div className="box-shadow">
                    <div className="box-empresa">

                        {buscarCliente.length > 0 ? (
                            SearchFilter(dadosFetch, buscarCliente, buscarCliente, 'nomePlano').map((body, ind) => {
                                return (
                                    <span key={ind}>
                                        {PrintCard(body, setDados, setModal, modal, `cadastrar-plano?plano=${buscarCliente}`, 'plano')}

                                    </span>
                                )
                            })
                        ) : (

                            dadosFetch?.map((body, ind) => {


                                return (
                                    <span key={ind}>
                                        {PrintCard(body, setDados, setModal, modal, null, 'plano')}

                                    </span>
                                )

                            })
                        )
                        }




                    </div>
                </div>
            </div>


            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>{`${getDados?.nomePlano || ''}`}</ModalHeader>
                <ModalBody>
                    <PlanoCadastrado dados={getDados} setModal={setModal} modal={modal} />
                </ModalBody>

            </Modal>
        </>
    )
}
