import './cadastrarTerminal.css'
import '../../../style/pageDentroModal.css'

import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import {
    Button, Input, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, ModalBody,
    Modal, ModalHeader, Progress, FormFeedback
} from 'reactstrap';

//importar função fetch
import { ConectURLPost } from '../../../util/conect'


//props alerta info cadastro
import SuccessEnvioDados from '../../../util/SuccessEnvioDados'

//Retirar caracteres especial 
import { RemoverCaracteres } from '../../../util/tools'

//validação de form
import { useForm } from 'react-hook-form';


//FormInpu paar validar dados
import FormInput from '../../../util/validarInput'



export default function CadastrarTerminal() {

    //validação de form 
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    //logo empresa
    const [imagem, setImagem] = useState();
    const [prevImagem, setPrevImagem] = useState();
    const [targetFiles, settargetFiles] = useState()

    //todos os dados do Form
    const [getContrato, setContrato] = useState();
    const [aguardeTempo, setaguardeTempo] = useState(false);

    //carregando dados recebido do fetch
    const [errorResponse, setErrrorResponse] = useState(false)
    const [loadResponse, setloadResponse] = useState(false)
    const [sucessoFetch, setsucessoFetch] = useState(false)
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);

    function recarregar(){
        window.location.reload()
    }

    return (

        <>

            <Modal isOpen={true} fullscreen>
                <ModalHeader id='modalTopo'>
                    <div>Cadastro de terminal</div>
                    
                    {sucessoFetch && <Navigate to="/listar-terminais" replace={true} />}
                    {aguardeTempo && recarregar()}
                </ModalHeader>
                <ModalBody>

                    <div id='cadastrar-cliente'>


                        <div className="box-shadow">

                            <Form id='dadosDaEmpresa'>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="nomeEmpresa">
                                                Nome da Empresa:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.nomeEmpresa ? true : false}
                                                type="text"
                                                name="nomeEmpresa"
                                                register={register}
                                                placeholder="Nome da empresa"
                                                id="nomeEmpresa"
                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>


                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="responsavelEmpresa">
                                                Responsável:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.responsavelEmpresa ? true : false}
                                                type="text"
                                                name="responsavelEmpresa"
                                                id="responsavelEmpresa"
                                                register={register}
                                                placeholder="Nome do Responsável"

                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="documentoEmpresa">
                                                CPF / CNPJ:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.documentoEmpresa ? true : false}
                                                type="text"
                                                name="documentoEmpresa"
                                                id="documentoEmpresa"
                                                register={register}


                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="whatsApp">
                                                WhatsApp:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.whatsApp ? true : false}
                                                type="number"
                                                name="whatsApp"
                                                id="whatsApp"
                                                register={register}

                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col >
                                        <FormGroup>
                                            <Label for="enderecoEmpresa">
                                                Endereço:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.enderecoEmpresa ? true : false}
                                                type="text"
                                                name="enderecoEmpresa"
                                                id="enderecoEmpresa"
                                                register={register}

                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>

                                    <Col >
                                        <FormGroup>
                                            <Label for="bairroEmpresa">
                                                Bairro::
                                            </Label>

                                            <FormInput
                                                invalid={errors?.bairroEmpresa ? true : false}
                                                type="text"
                                                name="bairroEmpresa"
                                                id="bairroEmpresa"
                                                register={register}

                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="cidadeEmpresa">
                                                Cidade:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.cidadeEmpresa ? true : false}
                                                type="text"
                                                name="cidadeEmpresa"
                                                id="cidadeEmpresa"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="estadoEmpresa">
                                                Estado:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.estadoEmpresa ? true : false}
                                                type="text"
                                                name="estadoEmpresa"
                                                id="estadoEmpresa"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="cepEmpresa">
                                                CEP:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.cepEmpresa ? true : false}
                                                type="text"
                                                name="cepEmpresa"
                                                id="cepEmpresa"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>



                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="fluxoMedio">
                                                Fluxo médio:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.fluxoMedio ? true : false}
                                                type="text"
                                                name="fluxoMedio"
                                                id="fluxoMedio"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>

                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="inicioContrato">
                                                Início contrato:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.inicioContrato ? true : false}
                                                type="date"
                                                name="inicioContrato"
                                                id="inicioContrato"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="FimContrato">
                                                Fim contrato:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.FimContrato ? true : false}
                                                type="date"
                                                name="FimContrato"
                                                id="FimContrato"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>



                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="horarioFun">
                                                Horário funcionamento:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.horarioFun ? true : false}
                                                type="textarea"
                                                name="horarioFun"
                                                id="horarioFun"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup check>

                                    <FormInput
                                        invalid={errors?.empresaAtiva ? true : false}
                                        type="checkbox"
                                        name="empresaAtiva"
                                        id="empresaAtiva"
                                        register={register}

                                    />



                                    <Label
                                        check
                                        for="empresaAtiva"
                                    >
                                        Empresa ativa
                                    </Label>
                                </FormGroup>

                            </Form>
                        </div>



                        <div className="box-footer">
                            <Card style={{ border: 'none' }}>
                                <CardFooter>

                                    {!sucessoFetch ?
                                        (<Button color="success" onClick={() => {

                                            //rota,getContrato,setloadResponse,setsucessoFetch,setaguardeTempo                                            

                                            handleSubmit((data) => {

                                               
                                                ConectURLPost(
                                                    'cadastrar-terminal',
                                                    data,
                                                    setloadResponse,
                                                    setsucessoFetch,
                                                    setaguardeTempo,
                                                    setErrrorResponse
                                                )
                                            })()


                                        }}>
                                            Salvar
                                        </Button>)

                                        : null
                                    }




                                </CardFooter>
                            </Card>
                        </div>
                        {loadResponse && <Progress
                            animated
                            value={100}
                            color="warning"
                        />}
                    </div>
                </ModalBody>


                <SuccessEnvioDados
                    sucessoFetch={sucessoFetch} //
                    menssagem={'Terminal cadastrado'}
                    visible={visible}
                    onDismiss={onDismiss}
                />

                <SuccessEnvioDados
                    sucessoFetch={errorResponse} //
                    menssagem={'Não foi possivel se conectar com oservidor. Tente novamente mais tarde.'}
                    visible={visible}
                    onDismiss={onDismiss}
                    danger={'danger'}
                />

            </Modal>

        </>


    )
}


