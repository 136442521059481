import './anunciante.css'
import '../../../style/pageDentroModal.css'

import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import {
    Button, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, ModalBody,
    Modal, ModalHeader, Progress, FormFeedback, FormText
} from 'reactstrap';

//importar função fetch
import { ConectURLPost } from '../../../util/conect'


import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';


//props alerta info cadastro
import SuccessEnvioDados from '../../../util/SuccessEnvioDados'

//validação de form
import { useForm } from 'react-hook-form';

//FormInpu paar validar dados
import FormInput from '../../../util/validarInput'


export default function CadastrarCliente() {


    
    const {  dataBase, _ } = useContext(UserContext)
   
    //validação de form 
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();




    //todos os dados do Form    
    const [aguardeTempo, setaguardeTempo] = useState(false);

    //carregando dados recebido do fetch
    const [errorResponse, setErrrorResponse] = useState(false)
    const [loadResponse, setloadResponse] = useState(false)
    const [sucessoFetch, setsucessoFetch] = useState(false)
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);



    return (

        <>

            <Modal isOpen={true} fullscreen>
                <ModalHeader id='modalTopo'>
                    <div>Cadastro de cliente</div>
                    <Link className='btn-close' to={sucessoFetch ? '/listar-clientes' : '/'}></Link>
                    {aguardeTempo && <Navigate to="/listar-clientes" replace={true} />}
                    {aguardeTempo && window.location.reload()}
                </ModalHeader>
                <ModalBody>



                    <div id='cadastrar-cliente'>

                        <div className="box-shadow">

                            <Form id='dadosDaEmpresa'>



                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="nomeEmpresa">
                                                Nome da Empresa:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.nomeEmpresa ? true : false}
                                                type="text"
                                                name="nomeEmpresa"
                                                register={register}
                                                placeholder="Nome da empresa"
                                                id="nomeEmpresa"
                                            />
                                            <FormFeedback >
                                                Nome da empresa é necessário!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="responsavelEmpresa">
                                                Responsável:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.responsavelEmpresa ? true : false}
                                                type="text"
                                                name="responsavelEmpresa"
                                                id="responsavelEmpresa"
                                                register={register}
                                                placeholder="Nome do Responsável"

                                            />
                                            <FormFeedback >
                                                Nome do responsável é necessário!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="documentoEmpresa">
                                                CPF / CNPJ:
                                            </Label>


                                            <FormInput
                                                invalid={errors?.documentoEmpresa ? true : false}
                                                type="text"
                                                name="documentoEmpresa"
                                                id="documentoEmpresa"
                                                register={register}


                                            />
                                            <FormFeedback >
                                                Documento da empresa é necessário!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="whatsApp">
                                                WhatsApp:
                                            </Label>


                                            <FormInput
                                                invalid={errors?.whatsApp ? true : false}
                                                type="number"
                                                name="whatsApp"
                                                id="whatsApp"
                                                register={register}

                                            />
                                            <FormFeedback >
                                                WhatsApp é necessário!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col >
                                        <FormGroup>
                                            <Label for="enderecoEmpresa">
                                                Endereço:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.enderecoEmpresa ? true : false}
                                                type="text"
                                                name="enderecoEmpresa"
                                                id="enderecoEmpresa"
                                                register={register}

                                            />
                                            <FormFeedback >
                                                Endereço da empresa é necessário!
                                            </FormFeedback>


                                        </FormGroup>
                                    </Col>

                                    <Col >
                                        <FormGroup>
                                            <Label for="bairroEmpresa">
                                                Bairro::
                                            </Label>

                                            <FormInput
                                                invalid={errors?.bairroEmpresa ? true : false}
                                                type="text"
                                                name="bairroEmpresa"
                                                id="bairroEmpresa"
                                                register={register}

                                            />
                                            <FormFeedback >
                                                Bairro da empresa é necessário!
                                            </FormFeedback>


                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="cidadeEmpresa">
                                                Cidade:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.cidadeEmpresa ? true : false}
                                                type="text"
                                                name="cidadeEmpresa"
                                                id="cidadeEmpresa"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Cidade da empresa é necessário!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="estadoEmpresa">
                                                Estado:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.estadoEmpresa ? true : false}
                                                type="text"
                                                name="estadoEmpresa"
                                                id="estadoEmpresa"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Estado da empresa é necessário!
                                            </FormFeedback>


                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="cepEmpresa">
                                                CEP:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.cepEmpresa ? true : false}
                                                type="number"
                                                name="cepEmpresa"
                                                id="cepEmpresa"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                CEP da empresa é necessário!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup check>

                                    <FormInput

                                        type="checkbox"
                                        name="empresaAtiva"
                                        id="empresaAtiva"
                                        register={register}

                                    />

                                    <Label
                                        check
                                        for="empresaAtiva"
                                    >
                                        Empresa ativa
                                    </Label>



                                </FormGroup>

                            </Form>
                        </div>



                        <div className="box-footer">
                            <Card style={{ border: 'none' }}>
                                <CardFooter>

                                    {!sucessoFetch ?
                                        (<Button color="success" onClick={() => {
                                           
                                            //rota,getContrato,setloadResponse,setsucessoFetch,setaguardeTempo        
                                            
                                                                                        handleSubmit((data) => {
                                                                                          
                                                                                           const body = {...data, db_nome: dataBase}
                                                                                            
                                                                                            ConectURLPost(
                                                                                                'cadastro-cliente',
                                                                                                body,                                                                                                
                                                                                                setloadResponse,
                                                                                                setsucessoFetch,
                                                                                                setaguardeTempo,
                                                                                                setErrrorResponse,
                                                                                                
                                                                                            )
                                                                                            
                                                                                        })()
                                            

                                        }}>
                                            Cadastrar
                                        </Button>)

                                        : null
                                    }




                                </CardFooter>
                            </Card>
                        </div>
                        {loadResponse &&
                            <Progress
                                animated
                                value={100}
                                color="warning"
                            />}
                    </div>
                </ModalBody>


                <SuccessEnvioDados
                    sucessoFetch={sucessoFetch} //
                    menssagem={'Cliente cadastrado'}
                    visible={visible}
                    onDismiss={onDismiss}
                />

                <SuccessEnvioDados
                    sucessoFetch={errorResponse} //
                    menssagem={'Não foi possivel se conectar com oservidor. Tente novamente mais tarde.'}
                    visible={visible}
                    onDismiss={onDismiss}
                    danger={'danger'}
                />

            </Modal>

        </>


    )
}


