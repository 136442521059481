
import Cookies from 'js-cookie';
import './subTopo.css'

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export default function SubMenu() {

    function getNome() {

        if (Cookies.get('stk-log')) {
            return JSON.parse(Cookies.get('stk-log'))?.nome
        } else {
            return ''
        }


    }


    return (
        <>
            <div className="stk-body">
                <div className='stk-home'>

                    <div>Olá, {getNome()}</div>

                    <div data-tooltip="Sair" onClick={() => {

                        Cookies.remove('stk-log')

                        setTimeout(() => {
                            window.location.reload()
                        }, 100)
                    }}>
                        <ExitToAppIcon />
                    </div>


                </div>
            </div>
        </>
    )
}