

import './svgMenu.css'


export default function SvgMenu({setOpenMenu}) {
    return (

        <svg id='svgMenu' fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
         onClick={()=>{
            
            setOpenMenu((open)=> !open)
        }}>
            <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff"   />
        </svg>


    )
}