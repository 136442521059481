import './menu.css'
import { Link } from 'react-router-dom'
import logoSTk from '../../img/icone-stk.png'
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

//icones menu mui componets

import CampaignIcon from '@mui/icons-material/Campaign'; //anucios
import GroupIcon from '@mui/icons-material/Group'; //users
import GroupAddIcon from '@mui/icons-material/GroupAdd'; //cadastrar cliente
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'; //terminial tv
import AddToQueueIcon from '@mui/icons-material/AddToQueue'; //cadastrar terminal
import TvIcon from '@mui/icons-material/Tv';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import BallotIcon from '@mui/icons-material/Ballot';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SettingsIcon from '@mui/icons-material/Settings';


export default function MenuPrincipal({ openMenu, setOpenMenu }) {

    const openCloseMenu = () => setOpenMenu((open) => !open)
    const { tema, setTema } = useContext(UserContext)

    return (

        <>
            {openMenu && (
                <div id="bg-menu-fundo"  onClick={openCloseMenu}></div>

            )}

            <nav id='menu-nav' className={openMenu ? `showMenu ${tema}-nav`  : `hideMenu ${tema}-nav` } >
                <div className="logoUser">

                    <Link to={'/'} onClick={openCloseMenu}>
                        <img src={logoSTk} alt='Logo'></img>
                    </Link>


                </div>
                <div className="navegacao">
                    <Sidebar backgroundColor={tema === 'padrao' ? '#e8e8e8' : '#333333'} collapsed={false}  >
                        <Menu >
                            <MenuItem icon={<CampaignIcon />} component={<Link to={'/novo-anuncio'} onClick={openCloseMenu}></Link>}>Novo anúncio</MenuItem>

                            <SubMenu icon={<GroupIcon />} label="Clientes">
                                <MenuItem icon={<GroupIcon />} component={<Link to={'/listar-clientes'} onClick={openCloseMenu}></Link>}>Listar clientes</MenuItem>
                                <MenuItem icon={<GroupAddIcon />} component={<Link to={'/cadastrar-cliente'} onClick={openCloseMenu}></Link>}>Cadastrar cliente</MenuItem>
                            </SubMenu>

                            <SubMenu icon={<DisplaySettingsIcon />} label="Terminal">
                                <MenuItem icon={<TvIcon />} component={<Link to={'/listar-terminais'} onClick={openCloseMenu}></Link>}>Listar terminais</MenuItem>
                                <MenuItem icon={<AddToQueueIcon />} component={<Link to={'/cadastrar-terminal'} onClick={openCloseMenu}></Link>}>Cadastrar terminal</MenuItem>
                            </SubMenu>

                            <SubMenu    icon={<HomeRepairServiceIcon />} label="Planos" >
                                <MenuItem icon={<FormatListNumberedIcon />} component={<Link to={'/listar-planos'} onClick={openCloseMenu}></Link>}>Listar planos</MenuItem>
                                <MenuItem icon={<BallotIcon />} component={<Link to={'/cadastrar-plano'} onClick={openCloseMenu}></Link>}>Cadastrar plano</MenuItem>
                            </SubMenu>

                            <SubMenu icon={<PriceChangeIcon />} label="Financeiro">
                                <MenuItem icon={<AttachMoneyIcon />} component={<Link to={'/listar-anuncios'} onClick={openCloseMenu}></Link>}>Anúncios</MenuItem>
                                <MenuItem icon={<PointOfSaleIcon />} component={<Link to={'/caixa'} onClick={openCloseMenu}></Link>}>Caixa</MenuItem>
                            </SubMenu>                            

                            <MenuItem icon={<SettingsIcon />} component={<Link to={'/configuracoes'} onClick={openCloseMenu}></Link>}>Configurações</MenuItem>

                        </Menu>
                    </Sidebar>
                </div>

                
            </nav >
        </>


    )
}

